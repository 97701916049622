import { Box, Center, VStack, Text } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import MainNav from "../../../components/MainNav";

export default function PredictiveAnalytics() {
  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={'Predictive Analytics'} />
      <Box paddingX={{ base: 4, md: "20px", lg: "150px" }} paddingTop={'100px'}>
        {/* <SubNav
          title={"Predictive Analytics"}
          icon={<Icons type="plus" />}
          show={false}
          //onClick={onOpen}
        /> */}
        <Center>
          <VStack>
            <Text fontWeight={"700"} color={"red"} textAlign={"center"}>
              PREDICTIVE ANALYTICS FOR LOTTERY NUMBER
            </Text>
          </VStack>
        </Center>
        <Box>
          <VStack alignItems={"flex-start"}>
            <Text>Description: </Text>
            <Text>
              Learn about predictive analytics and how it can be used to enhance
              lottery predictions by analyzing historical data.
            </Text>
          </VStack>
          <Center paddingTop={4}>
            <Text fontWeight={"700"}> BASICS OF PREDICTIVE ANALYTICS</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>Overview and Importance: </Text>
            <Text>
              Predictive analytics is a branch of advanced analytics used to
              make predictions about future events based on historical data. By
              leveraging statistical techniques, data mining, machine learning,
              and artificial intelligence, predictive analytics identifies
              patterns and trends within data sets that can forecast future
              occurrences.
            </Text>
            <Text>Overview: </Text>
            <Text>
              Predictive analytics transforms historical lottery data into
              actionable insights, helping users make more informed decisions
              when selecting lottery numbers. This process involves collecting
              and analyzing large volumes of past lottery results to identify
              trends and patterns that can suggest the likelihood of specific
              numbers being drawn in the future.
            </Text>
            <Text>Importance: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Data-Driven Decisions: Predictive analytics empowers users to
                make decisions based on data rather than intuition or guesswork,
                thereby increasing the chances of selecting winning numbers.
                <br />
                <br />
                - Identifying Patterns: By analyzing historical data, predictive
                analytics can uncover patterns that are not immediately
                apparent, providing a strategic advantage in number selection.
                <br />
                <br />- Risk Reduction: Utilizing predictive analytics can
                reduce the risk of consistently choosing non-winning numbers, as
                it offers a scientifically backed method for number selection.
                <br />
                <br />- Personalization: Predictive analytics can be tailored to
                individual user preferences and behaviors, providing a
                customized experience that aligns with the user’s specific
                lottery playing habits.
              </Text>
            </Box>
          </VStack>
          <Center paddingTop={4}>
            <Text fontWeight={"700"}> ALGORITHM INSIGHTS</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>Explanation of Algorithms Used: </Text>
            <Text>
              Predictive analytics for lottery predictions involves several
              sophisticated algorithms, each with unique capabilities for
              analyzing data and generating predictions. Here’s an in-depth look
              at some key algorithms used:
            </Text>
            <>
              <Text>- Regression Analysis: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Overview: Regression analysis examines the relationship
                  between variables to predict future values. In the context of
                  lottery predictions, it can analyze the frequency and patterns
                  of past winning numbers.
                  <br />
                  <br />
                  - Types: Linear regression and logistic regression are common
                  types used. Linear regression predicts outcomes based on the
                  relationship between two continuous variables, while logistic
                  regression is used for binary outcomes (e.g., predicting if a
                  number will be drawn or not).
                  <br />
                  <br />- Application: By identifying trends and correlations in
                  past lottery results, regression analysis can suggest which
                  numbers are more likely to be drawn.
                </Text>
              </Box>
            </>
          </VStack>
          <>
            <Text>- Time Series Analysis: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Overview: Time series analysis involves analyzing data points
                collected or recorded at specific time intervals to identify
                trends, seasonal patterns, and cyclical movements.
                <br />
                <br />
                - Components: This includes trend analysis (long-term movement
                in data), seasonality (regular variations tied to specific time
                periods), and cycles (irregular upward or downward movements).
                <br />
                <br />- Application: Time series analysis can forecast future
                lottery numbers based on the observed patterns over time.
              </Text>
            </Box>
          </>
          <>
            <Text>- Neural Networks: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Overview: Neural networks are computational models inspired by
                the human brain, capable of recognizing complex patterns and
                making predictions.
                <br />
                <br />
                - Structure: They consist of interconnected nodes (neurons)
                organized in layers. Each connection has a weight adjusted
                during training to minimize prediction errors.
                <br />
                <br />- Application: Neural networks can process vast amounts of
                historical lottery data to identify intricate patterns and
                correlations that simpler algorithms might miss.
              </Text>
            </Box>
          </>
          <>
            <Text>-Machine Learning Models: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Decision Trees: These models use a tree-like graph of
                decisions and their possible consequences, identifying the most
                likely outcomes based on historical data.
                <br />
                <br />
                - Random Forests: An ensemble method that uses multiple decision
                trees to improve prediction accuracy and avoid overfitting.
                <br />
                <br />- Support Vector Machines (SVM): These models find the
                optimal boundary that separates different classes in the data,
                useful for predicting binary outcomes like whether a number will
                be drawn or not.
              </Text>
            </Box>
          </>
          <Center paddingTop={4}>
            <Text fontWeight={"700"}> PERSONALIZATION TECHNIQUES</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>How Personalized Suggestions are Generated: </Text>
            <Text>
              Personalization is a key feature of the Win Wise app, ensuring
              that lottery number suggestions are tailored to individual users’
              preferences and behaviors. Here’s how personalized suggestions are
              generated:
            </Text>

            <>
              <Text>- User Data Collection: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Input Data: Users provide information such as preferred
                  lottery games, favorite numbers, birth dates, and other
                  relevant details.
                  <br />
                  <br />- Behavioral Data: The app tracks user interactions,
                  such as which numbers are selected most often and which
                  lotteries are played frequently.
                </Text>
              </Box>
            </>
            <>
              <Text>- Data Analysis: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Pattern Recognition: The app analyzes the collected data to
                  identify patterns in user behavior and preferences. This can
                  include frequently chosen numbers, preferred combinations, and
                  timing of lottery participation.
                  <br />
                  <br />- Trend Analysis: The app examines trends in the user’s
                  lottery playing history, looking for any consistent patterns
                  that can inform future predictions.
                </Text>
              </Box>
            </>
            <>
              <Text>- Algorithm Application: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Customized Algorithms: Based on the user’s data, the app
                  employs specific algorithms that best match the user’s
                  behavior. For instance, if a user often plays a particular
                  lottery game, the app uses historical data from that game to
                  generate predictions.
                  <br />
                  <br />- Real-Time Adjustments: Algorithms continuously learn
                  from new data, adjusting predictions as more user data is
                  collected, ensuring that suggestions remain relevant and
                  accurate over time.
                </Text>
              </Box>
            </>
            <>
              <Text>- Personalized Predictions: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Tailored Suggestions: The app generates number suggestions
                  that align with the user’s unique preferences and historical
                  data, increasing the likelihood of user satisfaction and
                  potential lottery success.
                  <br />
                  <br />
                  - User Feedback Loop: Users can provide feedback on
                  the accuracy and usefulness of the suggestions, allowing the
                  app to refine its predictive models and enhance
                  personalization further.
                </Text>
              </Box>
            </>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
}
