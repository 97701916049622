import { Box, Center, VStack, Text } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import MainNav from "../../../components/MainNav";

export default function EducationalNumerologyInsight() {
  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={"Numerology-Based Insight"} />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "90px", md: "90px" }}
      >
        {/* <SubNav
          title={"Numerology-Based Insight"}
          icon={<Icons type="plus" />}
          show={false}
          //onClick={onOpen}
        /> */}

        <Box>
          <Center paddingTop={1}>
            <Text fontWeight={"700"}> NUMEROLOGY-BASED INSIGHTS</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>Description: </Text>
            <Text>
              Numerology is an ancient metaphysical science that explores the
              relationship between numbers and life events. By understanding
              numerology principles and calculations, users can enhance their
              lottery number selection process. This section provides a concise
              overview, guiding users on how to apply numerology effectively.
            </Text>
            <Text fontWeight={"700"}>- Introduction to Numerology: </Text>
            <Text>
              - Basic Principles and Key Concepts:
              <br />
              <br />
              - Numerology is based on the belief that numbers hold significant
              vibrational patterns and can influence various aspects of life.
              Each number has its unique attributes and meanings.
              <br />
              <br />
              - Historical Background and Applications:
              <br />
              <br />- Originating in ancient civilizations, numerology has been
              used for centuries to gain insights into personality traits, life
              paths, and future events. Its applications extend from personal
              growth to practical uses like lottery number selection.
            </Text>
            <Text fontWeight={"700"}>- Core Numerology Numbers: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Explanation of Core Numbers Used in Numerology:
                <br />
                <br />
                - Core numerology numbers include the Birth Number, Expression
                Number, and Soul Urge Number, among others. Each of these
                numbers is derived from specific personal data, such as birth
                dates and names.
                <br />
                <br />
                - How to Calculate and Interpret Them:
                <br />
                - Birth Number: Calculated from the date of birth, it reveals
                essential life themes and challenges. - Expression Number:
                Derived from the full name at birth, it highlights talents,
                abilities, and goals.
                <br />
                <br />- Soul Urge Number: Based on the vowels in the full birth
                name, it reflects inner desires and motivations. - To read more
                and gain a deeper understanding, visit the Basics of Numerology
                section.
              </Text>
            </Box>
            <Text fontWeight={"700"}>
              - Numerology in Lottery Predictions:{" "}
            </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - How Numerology Influences Lottery Number Selection:
                <br />
                <br />
                - Numerology can guide users in selecting lottery numbers that
                resonate with their personal numerology profile. By aligning
                number choices with personal vibrational patterns, users may
                enhance their chances of selecting winning numbers.
                <br />
                <br />
                - Practical Examples and Case Studies:
                <br />
                <br />- This section will provide real-life examples and case
                studies where individuals have successfully used numerology to
                guide their lottery number selections. By studying these cases,
                users can better understand the practical application of
                numerology in lottery predictions.
              </Text>
            </Box>
            <Text fontWeight={"600"}>
              To read more and have a better understanding of each of these
              topics, visit the Basics of Numerology section. Here, users will
              find detailed explanations, step-by-step calculation guides, and
              comprehensive insights into how numerology can be applied to
              various aspects of life, including lottery predictions.{" "}
            </Text>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
}
