import React from "react";
import {
  FormControl,
  // FormErrorMessage,
  // FormHelperText,
  // FormLabel,
  Input,
  Box,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";

interface CustomInputProps {
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onChange?: any;
  onChangeText?: any;
  isRequired?: boolean;
  extraText?: string;
  width?: string | number;
  height?: string | number;
  maxheight?: string | number;
  children?: any;
  isInvalid?: boolean;
  errorMessage?: string;
  ref?: any;
  path?: any;
  paddingLeft?: any;
  bgColor?: any;
  marginBottom?: any;
  my?: any;
  color?: any;
  rightElement?: any;
  rightElementClick?: any;
}

type RequireProperty<T, Prop extends keyof T> = T & { [key in Prop]-?: T[key] };
type RequireInputProps =
  | RequireProperty<CustomInputProps, "label">
  | RequireProperty<CustomInputProps, "placeholder">;

const InputField = ({
  name,
  label,
  placeholder = " ",
  type = "text",
  value,
  onChange,
  onChangeText,
  isRequired = true,
  extraText = "",
  children,
  width = "100%",
  height = "60px",
  // maxheight,
  errorMessage,
  isInvalid = false,
  ref,
  path,
  bgColor,
  paddingLeft,
  marginBottom,
  rightElement,
  rightElementClick,
  my,
  color,
  ...props
}: RequireInputProps) => {
  return (
    <Box
      my={my}
      width={width}
      minHeight={height ? "60px" : height}
      {...props}
    >
      <FormControl variant="floating" id={name} isInvalid={isInvalid}>
        <InputGroup>
          <Input
            ref={ref}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            color={color || "white"}
            bgColor={bgColor}
            minHeight={height || "50px"}
            // maxHeight={maxheight}
            borderRadius={"30px"}
            paddingTop={type === "textarea" ? 0 : 0}
            paddingLeft={type === "textarea" ? 0 : paddingLeft}
            marginBottom={marginBottom}
            
          />

          {/* <FormLabel>{label}</FormLabel> */}

          {/* {extraText && <FormHelperText>{extraText}</FormHelperText>} */}
          {rightElement && (
            <InputRightElement onClick={rightElementClick} paddingTop={"5px"}>
              {rightElement}
            </InputRightElement>
          )}
        </InputGroup>

        {children}
      </FormControl>
    </Box>
  );
};

export { InputField };
