import React from "react";
import HomeNavbar from "../components/HomeNavbar";
import {
  Box,
  Text,
  Image,
  Center,
  // Show,
  // Hide
} from "@chakra-ui/react";
import DashboardImage from "../assets/winsise-image.jpg";
import { colors } from "../theme/colors";
import Group1 from "../assets/Group1.svg";
import Group2 from "../assets/Group 2.svg";
import Group3 from "../assets/Group3.svg";
import Group4 from "../assets/Group 4.svg";
import Button from "../components/Button";
import Icons from "../assets/Icons";
import { items } from "../components/items";
import FAQ from "./FAQ";
import { InputField } from "../components/InputField";

import Jackport1 from "../assets/jackport1.svg";
import Jackport2 from "../assets/jackport2.svg";
import Jackport3 from "../assets/jackport3.svg";
import { useNavigate } from "react-router-dom";

interface CardProps {
  image: string;
  title: string;
  body: string;
  id: string;
  bgColor: string;
  color: string;
}

interface WorksProps {
  id: string;
  num: string;
  p: string;
}

export default function LandingPage() {
  const navigation = useNavigate();

  const card: CardProps[] = [
    {
      image: Group2,
      title: "Payment Integration  ",
      body: "Multiple payment methods make it easy to make safe transactions, which makes managing subscriptions and making in-app purchases easy.",
      id: "0",
      bgColor: "#FEFBF5",
      color: "#F36F56",
    },
    {
      image: Group3,
      title: "Numerology-Based Insights ",
      body: "When you use our numerology-based insights, you can enter personal information like your birthday to get a personalized reading. These tips, which come with full guides, use tried-and-true numerological principles to help you improve your lottery tactics. ",
      id: "1",
      bgColor: "#F2F9F1",
      color: "#0E6A01",
    },
    {
      image: Group4,
      title: "Dream Interpretation ",
      body: "With our dream interpretation tool, you can write down your dreams and use our large dream dictionary to figure out what numbers might come up in the lottery. This gives your lottery play a unique and personal touch. ",
      id: "2",
      bgColor: "#F2F6FC",
      color: "#4E7BD3",
    },
    {
      image: Group1,
      title: "Real-Time Data Updates ",
      body: "Get real-time data updates that make sure you never miss a draw score. You'll always know what's going on because push messages let you know right away when your numbers come up.",
      id: "3",
      bgColor: "#FBFEF6",
      color: "#F7CCf7",
    },
  ];

  const works: WorksProps[] = [
    {
      id: "0",
      num: "1",
      p: "Sign Up and Personalize: Easily register and customize your profile with essential details like your birthdate. ",
    },
    {
      id: "1",
      num: "2",
      p: "Explore Predictive Insights: Choose your lottery and receive personalized number suggestions based on advanced algorithms and numerology insights.",
    },
    {
      id: "2",
      num: "3",
      p: "Log Dreams, Get Numbers: Record your dreams and let Winwise interpret them to suggest potential winning numbers.",
    },
    {
      id: "3",
      num: "4",
      p: "Stay Updated, Play Smart: Receive real-time updates on lottery results and use this information to make informed decisions. ",
    },
    {
      id: "4",
      num: "5",
      p: "Manage Payments and Engage: Securely handle subscriptions, participate in community discussions, and access educational resources for a richer gaming experience.  ",
    },
  ];

  const Card = ({ title, body, image, bgColor, id, color }: any) => {
    return (
      <Box
        width={{ base: "100%", md: "48%" }}
        height={{ base: "250px", md: "300px", lg: "400px" }}
        bgColor={bgColor}
        id={id}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        padding={{ base: "10px", md: "15px", lg: "30px" }}
        marginTop={"20px"}
        borderRadius={15}
      >
        <Box alignSelf={"flex-end"}>
          <Image
            src={image}
            width={{ base: "80px", md: 100, lg: 150 }}
            alignSelf={"flex-end"}
            display={"flex"}
          />
        </Box>
        <Box>
          <Text color={color} fontSize={{ md: 18, lg: 24 }}>
            {title}
          </Text>
          <Text
            width={"90%"}
            fontSize={{ base: 12, md: 14, lg: 16 }}
            color={colors.darkGrey}
          >
            {body}
          </Text>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <HomeNavbar />

      <Box paddingX={{ base: "0px", md: "30px", lg: "50px" }} paddingTop={10}>
        <Box
          display={"flex"}
          flexDir={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          paddingX={{ base: "20px", md: 0 }}
        >
          <Box>
            <Text
              fontSize={{ base: "24px", md: "30px", lg: "44px" }}
              textAlign={{ base: "center", md: "left" }}
              fontWeight={"700"}
              //fontFamily={"Open Sans"}
              width={{ base: "100%", md: "80%" }}
            >
              Discover a New Path to Personal Growth with
              <Text
                color={colors.primary}
                fontSize={{ base: "24px", md: "30px", lg: "44px" }}
              >
                WinWise
              </Text>
            </Text>
            <Text
              textAlign={{ base: "center", md: "left" }}
              width={{ base: "100%", md: "80%", lg: "75%" }}
              paddingBottom={{ md: "30px", lg: "50px" }}
              fontSize={{ base: 12, md: "14px", lg: "16px" }}
            >
              WinWise is a trip that will change your life. It combines ancient
              knowledge with modern technology. Dive into the depths of
              self-discovery with our all-in-one platform that combines in-depth
              numerology insights, deep dream readings, and personalized tools
              for personal growth. You will get customized help that will help
              you reach your full potential, learn more about yourself, and live
              a happier life. Get the information you need to make smart
              choices, know who you really are, and face life's problems with
              confidence with WinWise. Change the way you look at personal
              growth by joining us.
            </Text>
            <Box
              paddingTop={{ base: 5, md: 0 }}
              marginLeft={{ base: "80px", md: 0 }}
            >
              <Button
                onClick={() => {
                  navigation("/login");
                }}
                label={"Get Started"}
                bgColor={colors.primary}
                paddingX={"10px"}
                height={{ base: "40px", md: "50px" }}
                width={{ base: "150px", md: "194px" }}
                borderRadius={"33px"}
                color="white"
                fontWeight={"700"}
                fontSize={16}
                icon={<Icons type="forward2" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              />
            </Box>
          </Box>
          <Box width={"100%"}>
            <Image
              src={DashboardImage}
              alt={"dashboardImage"}
              height={{ base: "300px", md: "200px", lg: "500px" }}
              borderRadius={10}
            />
          </Box>
        </Box>
        <Box
          paddingTop={{ md: "30px", lg: 0 }}
          paddingX={{ base: "20px", md: 0 }}
        >
          <Text
            fontFamily={"Open Sans"}
            fontSize={{ md: 24, lg: 36 }}
            fontWeight={"700"}
          >
            What We Offer
          </Text>
          <Text
            fontSize={{ base: 12, md: 16 }}
            width={{ base: "100%", md: "70%", lg: "50%" }}
          >
            Winwise changes the way you play lottery by putting together modern
            technology, old knowledge, and community feedback into a single app.
          </Text>
          <Box
            display={"flex"}
            flexDir={{ base: "column", md: "row" }}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            paddingBottom={3}
          >
            {card.map((item, id) => {
              return (
                <>
                  <Card
                    image={item.image}
                    title={item.title}
                    bgColor={item.bgColor}
                    id={item.id}
                    body={item.body}
                    color={item.color}
                  />
                </>
              );
            })}
          </Box>
        </Box>
        <Box paddingTop={"50px"} paddingX={{ base: "20px", md: 0 }}>
          <Text
            fontFamily={"Open Sans"}
            fontSize={{ md: 24, lg: 36 }}
            fontWeight={"700"}
          >
            HOW WINWISE WORKS
          </Text>
          {/* <Text>Experience a revolutionary platform that seamlessly</Text> */}
          <Box
            display={"flex"}
            flexDir={{ base: "column", md: "row" }}
            //justifyContent={'space-between'}
            paddingY={{ md: 10, lg: 20 }}
          >
            <Box>
              {works.map((item, id) => {
                return (
                  <Box
                    paddingY={{ md: "10px", lg: 15 }}
                    display={"flex"}
                    flexDir={{ base: "column", md: "row" }}
                    alignItems={{ base: "center", md: "flex-start" }}
                  >
                    <Box
                      width={{ base: "10%", md: "16%", lg: "10%" }}
                      height={{ base: "30px", md: "30px", lg: "38px" }}
                      borderRadius={"100%"}
                      bgColor={colors.primary}
                      //display={'flex'}
                      alignItems={"center"}
                      marginTop={{ base: 3, md: 0 }}
                    >
                      <Text
                        color={"white"}
                        textAlign={"center"}
                        paddingTop={{ base: "3px", md: "4px", lg: "6px" }}
                        fontSize={{ md: 16, lg: 18 }}
                      >
                        {item.num}
                      </Text>
                    </Box>
                    <Box paddingLeft={{ md: 5, lg: 10 }}>
                      <Text
                        width={{ base: "100%", md: "80%" }}
                        fontSize={{ base: 13, md: 14, lg: 16 }}
                        textAlign={{ base: "center", md: "left" }}
                      >
                        {item.p}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              width={{ md: "100%", lg: "80%" }}
              height={{ base: "200px", md: "300px" }}
              marginTop={{ base: "15px", md: 0 }}
              bgColor={colors.primary}
              borderRadius={20}
            ></Box>
          </Box>
        </Box>
        <Box
          paddingTop={"50px"}
          paddingBottom={{ base: 10, md: "50px" }}
          alignSelf={"center"}
          paddingX={{ base: "20px", md: 0 }}
        >
          <Text
            textAlign={"center"}
            fontSize={{ base: 16, md: 24 }}
            fontWeight={"700"}
          >
            Frequently Asked Questions
          </Text>
          <Center
            paddingTop={{ base: 4, md: 10 }}
            display={"flex"}
            flexDirection={"column"}
          >
            {items.map((item, id) => {
              return (
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignContent={"center"}
                  key={id}
                  marginLeft={{ base: "0px", md: "40px" }}
                >
                  <FAQ question={item.question} answer={item.answer} />
                </Box>
              );
            })}
          </Center>
        </Box>
        <Box
          display={"flex"}
          flexDir={{ base: "column", lg: "row" }}
          paddingY={{ base: 3, md: 20 }}
          paddingX={{ base: "20px", md: 0 }}
        >
          <Box>
            <Text
              fontSize={{ base: 18, md: 24 }}
              fontWeight={"700"}
              textAlign={{ base: "center", lg: "left" }}
            >
              Contact Us
            </Text>
            <Text
              width={{ base: "97%", md: "90%" }}
              textAlign={{ base: "center", lg: "left" }}
              fontSize={{ base: 13, md: 14, lg: 16 }}
              paddingBottom={{ base: 4, md: 10, lg: 0 }}
            >
              For any inquiries, assistance, or feedback, please don't hesitate
              to get in touch with us. Our dedicated support team is here to
              help you with any questions you may have. Feel free to reach out
              via the contact form on our website, and we'll get back to you as
              soon as possible. Your feedback is valuable to us as we strive to
              continually improve our services and provide you with the best
              experience possible. We look forward to hearing from you!
            </Text>
          </Box>
          <Box
            height={{ base: "600px", md: "650px" }}
            width={"100%"}
            bgColor={"#C1E2DA"}
            borderRadius={10}
            paddingX={{ base: "20px", md: "30px" }}
            paddingY={{ base: 7, lg: "30px" }}
          >
            <Text
              fontSize={{ base: 18, md: 24 }}
              fontWeight={"700"}
              color={"#3B454E"}
            >
              Get in Touch
            </Text>
            <Box>
              <Box marginBottom={"10px"}>
                <InputField
                  placeholder="Full name"
                  onChange={() => {}}
                  bgColor={"white"}
                  paddingLeft={10}
                  marginBottom={4}
                />
              </Box>
              <Box marginBottom={"10px"}>
                <InputField
                  placeholder="Email address"
                  onChange={() => {}}
                  bgColor={"white"}
                  paddingLeft={10}
                  marginBottom={4}
                />
              </Box>
              <Box marginBottom={"10px"}>
                <InputField
                  placeholder="Your subject"
                  onChange={() => {}}
                  bgColor={"white"}
                  paddingLeft={10}
                  marginBottom={4}
                />
              </Box>
              <InputField
                placeholder="Type your message"
                onChange={() => {}}
                bgColor={"white"}
                paddingLeft={10}
                height={"232px"}
              />
            </Box>
            <Box width={"100%"} paddingTop={"180px"}>
              {/* <Button onClick={() => { }}
                                label={"Submit"}
                                bgColor={colors.primary}
                                width={'100%'}
                                height={'50px'} /> */}
              <Button
                onClick={() => {}}
                label={"Submit"}
                bgColor={colors.primary}
                paddingX={"10px"}
                height={{ base: "40px", md: "50px" }}
                width={{ base: "100%" }}
                borderRadius={"33px"}
                color="white"
                fontWeight={"700"}
                fontSize={16}
                // icon={<Icons type="forward2" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              />
            </Box>
          </Box>
        </Box>
        <Box
          paddingTop={"100px"}
          paddingBottom={50}
          alignSelf={"center"}
          paddingX={{ base: "20px", md: 0 }}
        >
          <Text textAlign={"center"} fontWeight={"500"}>
            Supported by these companies
          </Text>
          <Box
            display={"flex"}
            flexDir={"row"}
            justifyContent={"space-between"}
            width="80%"
            alignItems={"center"}
            marginLeft={{ base: 5, md: "30px" }}
          >
            <Image
              src={Jackport3}
              alt="jackport1"
              width={{ base: 50, md: 100, lg: 200 }}
            />
            <Image
              src={Jackport1}
              alt="jackport1"
              width={{ base: 50, md: 100, lg: 200 }}
            />
            <Image
              src={Jackport2}
              alt="jackport1"
              width={{ base: 50, md: 100, lg: 200 }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
