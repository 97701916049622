import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../theme/colors";
import Button from "../../components/Button";
import Icons from "../../assets/Icons";
import { PayPalButtons } from "@paypal/react-paypal-js";

function YearlyTier() {
  return (
    <Box paddingTop={3}>
      <Text fontSize={18}>Yearly subscription has access to </Text>
      <Box paddingLeft={1}>
        <Text>-Personalized Numerology Reports</Text>
        <Text>-Advanced Dream Interpretations</Text>
        <Text>-Monthly Expert Webinars</Text>
        <Text>-Unlimited Access to Educational Content</Text>
        <Text>-Priority Customer Support</Text>
        <Text>-Exclusive Promotional Offers</Text>
      </Box>
      {/* <Box width={"100%"} paddingTop={"40px"}>
        <Button
          onClick={() => {
            //navigation("/create");
          }}
          label={"CREATE ACCOUNT"}
          bgColor={colors.primary}
          // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
          width={{ base: "100%", md: "100%" }}
          borderRadius={"33px"}
          height={"48px"}
          color="white"
          fontWeight={"400"}
          paddingX={4}
          fontSize={16}
          icon={<Icons type="forward" />}
          flexDirection={"row"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        />
      </Box> */}
       <Box paddingTop={10}>
        <PayPalButtons
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: "P-3RX065706M3469222L5IFM4I",
            });
          }}
          style={{
           // label: "subscribe",
          }}
        />
      </Box>
    </Box>
  );
}

export default YearlyTier;
