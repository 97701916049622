import {
  Box,
  Text,
  Image,
  Center,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { colors } from "../../theme/colors";
import Logo from "../../assets/Logo.svg";
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import Icons from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { fireLogin } from "../../redux/actions/authAction";
import { savetoken } from "../../redux/token";

function Login({
  loginLoading,
  loginSuccess,
  loginError,
  callLoginRequest,
}: any) {
  const [select, setSelect] = useState(false);
  const navigation = useNavigate();
  const [username_or_email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);

  const handleSelect = () => {
    setSelect(!select);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // function convertToLowerCase(inputString: any) {
  //   return inputString.toLowerCase();
  // }

  return (
    <Box
      paddingX={{ base: "20px", md: "15%" }}
      bgColor={colors.backgroundColor}
    >
      <Box
        //width={'full'}
        bgColor={colors.backgroundColor}
        height={"100vh"}
        //alignItems={'center'}
        paddingX={{ base: "20px", md: "20%", lg: "30%" }}
      >
        <Center paddingTop={"50px"}>
          <VStack width={"100%"}>
            <Image
              src={Logo}
              alt={"logo"}
              height={{ base: "45px", md: "100px" }}
            />
            <Text fontSize={20} fontWeight={"700"} color={colors.primary}>
              WINWISE
            </Text>
            <InputField
              placeholder="Username"
              width={"100%"}
              height={"48px"}
              color={"white"}
              value={username_or_email}
              onChange={(e: any) => {
                setUsername(e.target.value);
              }}
            />
            <InputField
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              width={"100%"}
              height={"48px"}
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              rightElement={<Icons type="hidePassword" width={20} />}
              rightElementClick={togglePasswordVisibility}
            />
            <Box width={"100%"}>
              <Button
                onClick={async () => {
                  const res = await callLoginRequest({ username_or_email, password });
                  // console.log(res.status, "res on sunday")
                  if (res.status === 200) {
                    console.log(loginSuccess);
                    console.log(res, "res on login")
                    navigation("/dashboard");
                    // localStorage.setItem("access-token", res?.data?.access)
                    savetoken(res?.data?.access);
                  } else {
                    console.log(loginError);
                    // console.log(res);
                    toast({
                      title: "error",
                      isClosable: true,
                      description: "invalid credentials or network problem",
                      duration: 2000,
                      status: "error",
                      position: "top",
                    });
                  }
                }}
                loading={loginLoading}
                label={"LOGIN"}
                bgColor={colors.primary}
                // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                width={{ base: "100%", md: "100%" }}
                borderRadius={"33px"}
                height={"48px"}
                color="white"
                fontWeight={"400"}
                paddingX={4}
                fontSize={16}
                icon={<Icons type="forward" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                hasIcon={true}
              />
              <HStack justifyContent={"space-between"} paddingTop={1}>
                <HStack>
                  <Box
                    width={"20px"}
                    height={"20px"}
                    borderRadius={"100%"}
                    bgColor={"white"}
                    paddingTop={"3.2px"}
                    paddingLeft={"3.2px"}
                  >
                    <Box
                      width={"13px"}
                      height={"13px"}
                      borderRadius={"100%"}
                      bgColor={select ? colors.primary : "#C4C4C4"}
                    />
                  </Box>
                  <Text color={"white"} onClick={handleSelect}>
                    Keep logged in
                  </Text>
                </HStack>
                <Text
                  as={"button"}
                  color={"white"}
                  textDecor={"underline"}
                  onClick={() => {
                    navigation("/forgot-password");
                  }}
                >
                  Forgot Password
                </Text>
              </HStack>
            </Box>
            <Box width={"100%"} paddingTop={"40px"}>
              <Button
                onClick={() => {
                  navigation("/create");
                }}
                label={"CREATE ACCOUNT"}
                bgColor={colors.primary}
                // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                width={{ base: "100%", md: "100%" }}
                borderRadius={"33px"}
                height={"48px"}
                color="white"
                fontWeight={"400"}
                paddingX={4}
                fontSize={16}
                icon={<Icons type="forward" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              />
            </Box>
            <Text
              color={"white"}
              onClick={() => {
                navigation("/privacy");
              }}
              textDecor={"underline"}
            >
              Terms and Condtions
            </Text>
          </VStack>
        </Center>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  loginLoading: state.auth.loginLoading,
  loginSuccess: state.auth.loginSuccess,
  loginError: state.auth.loginError,
});

const mapDispatch = (dispatch: any) => ({
  callLoginRequest: (data: any) => dispatch(fireLogin(data)),
});

export default connect(mapState, mapDispatch)(Login);
