// LOGIN
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//register
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

//forgot password
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

//new password
export const NEW_PASSWORD_LOADING = "NEW_PASSWORD_LOADING";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_ERROR = "NEW_PASSWORD_ERROR";

//verify account
export const VERIFY_LOADING = "VERIFY_LOADING";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";

//profile
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

//posts
export const POST_LOADING = "POST_LOADING";
export const POST_SUCCESS = "POST_SUCCESS";
export const POST_ERROR = "POST_ERROR";

//comments
export const COMMENT_LOADING = "COMMENT_LOADING";
export const COMMENT_SUCCESS = "COMMENT_SUCCESS";
export const COMMENT_ERROR = "COMMENT_ERROR";

//likes
export const LIKES_LOADING = "LIKES_LOADING";
export const LIKES_SUCCESS = "LIKES_SUCCESS";
export const LIKES_ERROR = "LIKES_ERROR";

//unlikes
export const UNLIKES_LOADING = "UNLIKES_LOADING";
export const UNLIKES_SUCCESS = "UNLIKES_SUCCESS";
export const UNLIKES_ERROR = "UNLIKES_ERROR";

//birthdate number prediction
export const BIRTHDATE_LOADING = "BIRTHDATE_LOADING";
export const BIRTHDATE_SUCCESS = "BIRTHDATE_SUCCESS";
export const BIRTHDATE_ERROR = "BIRTHDATE_ERROR";

//birthdate number prediction
export const DREAM_INTERPRETATION_LOADING = "DREAM_INTERPRETATION_LOADING";
export const DREAM_INTERPRETATION_SUCCESS = "DREAM_INTERPRETATION_SUCCESS";
export const DREAM_INTERPRETATION_ERROR = "DREAM_INTERPRETATION_ERROR";
