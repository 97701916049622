// import React from "react";

type Props = {
  question: string;
  answer: string;
};

export const items: Props[] = [
  {
    question: "What is Winwise and how does it work? ",
    answer:
      "Winwise is your go-to app for enhancing your lottery gaming experience. It uses cutting edge technology and knowledge of numerology to give you personalized number ideas for different lottery games. Users can also write down their dreams so that they can be interpreted as possible winning numbers.",
  },
  {
    question: "Is Winwise free to use, and how accurate are its predictions? ",
    answer:
      "Yes, Winwise offers free access to general predictions and insights. However, premium features are available through subscription plans. While Winwise strives to provide accurate predictions based on historical data and numerological insights, lottery outcomes are inherently unpredictable.  ",
  },
  {
    question: "How secure is my personal information with Winwise, and what payment methods are accepted? ",
    answer:
      "At Winwise, we care very much about your privacy and safety. We use standard security methods in the business world to keep your personal information safe. We accept a number of payment methods, such as credit/debit cards and e-wallets, to make sure that subscriptions and in-app purchases are easy and safe. ",
  },
  {
    question: "Can I participate in community discussions on Winwise, and how often is the content updated? ",
    answer:
      "Of course! Winwise has a lively group where people can share their experiences, tips, and strategies about using numerology and the lottery. Winwise also updates its predictions, educational materials, and community talks on a regular basis to keep users informed and interested.",
  },
  {
    question: "How can I subscribe to Winwise, and how can I contact support if needed?  ",
    answer:
      "You can subscribe to Winwise through the app by selecting a subscription plan and completing the payment process securely. If you have any questions or need assistance, you can contact Winwise for support through the app's contact section or via email. Our support team is dedicated to assisting you with any inquiries you may have. ",
  },
];
