import { Box, Center, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DashboardNav from "../../components/DashboardNav";
import NotificationCard from "./notification_card";
import Request from "../../api/request";
import axios from "axios";
import { loadtoken } from "../../redux/token";
import { ClipLoader } from "react-spinners";
// import { messaging } from "../../utils/firebase";

// export const requestNotificationPermission = async () => {
//   try {
//     await Notification.requestPermission();
//     // const token = await messaging.getToken();
//     console.log('FCM Token:');
//     // Send the token to your server to store it
//     return;
//   } catch (error) {
//     console.error('Error getting notification permission:', error);
//     throw error;
//   }
// };

export default function Notifications() {
  // const [loading, setIsLoading] = useState(false)
  const baseUrl = Request.url;
  const token = loadtoken();
  const [unreadCount, setUnreadCount] = useState(0);

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const fetchNotificationData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${baseUrl}/notifications`, {
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     setNotifications(response.data); // store the fetched data in state
  //     console.log(response.data, "data");
  //   } catch (error) {
  //     console.error("Error fetching notification data:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchNotificationData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/notifications`, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      const notifications = response.data;
      setNotifications(notifications);
      const unreadCount = notifications.filter(
        (notification: { read: any }) => !notification.read
      ).length;
      setUnreadCount(unreadCount);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);

  return (
    <Box>
      <DashboardNav />
      <Box
        paddingX={{ base: "20px", md: "5%", lg: "11.5%" }}
        paddingTop={"120px"}
      >
        <Text fontSize={28} width={{ base: "60%", md: "100%" }}>
          My Notifications
        </Text>
        {/* <Text>{`You have ${unreadCount} unread notifications`}</Text> */}
        <Center width={"100%"}>
          <Box width={"100%"}>
            {/* Display a loading indicator if data is still being fetched */}
            {isLoading && <ClipLoader size={21} color="black" />}

            {/* Render the notifications */}
            {!isLoading && notifications.length === 0 && (
              <Text>No notifications found.</Text>
            )}

            <Box width="100%">
              {notifications.map((notification: any, index: any) => (
                <Box
                  key={index}
                  //onClick={() => markAsRead(notification.id)}
                >
                  <Text>{notification.content}</Text>
                  {/* {!notification.read && (
                    <Text style={{ color: "red" }}>New</Text>
                  )} */}
                </Box>
              ))}
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}
