import axios from "axios";
import Request from "../../api/request";
import { loadtoken } from "../token";
import {
  COMMENT_ERROR,
  COMMENT_LOADING,
  COMMENT_SUCCESS,
  LIKES_SUCCESS,
  LIKES_ERROR,
  LIKES_LOADING,
  POST_ERROR,
  POST_LOADING,
  POST_SUCCESS,
} from "../reducers/types";

const baseUrl = Request.url;

export const makePost = (data: any) => async (dispatch: any) => {
  const token = loadtoken();
  dispatch({ type: POST_LOADING, payload: true });
  dispatch({ type: POST_ERROR, payload: null });

  try {
    const resp = await axios.post(`${baseUrl}/community/post/create`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    if (resp.status === 201) {
      dispatch({ type: POST_SUCCESS, payload: resp.data });
      dispatch({ type: POST_LOADING, payload: false });
    }
    return resp;
  } catch (error: any) {
    dispatch({ type: POST_LOADING, payload: false });
    dispatch({ type: POST_ERROR, payload: error.data });
  }
};

export const makeComment = (data: any) => async (dispatch: any) => {
  const token = loadtoken();
  dispatch({ type: COMMENT_LOADING, payload: true });
  dispatch({ type: COMMENT_ERROR, payload: null });

  try {
    const resp = await axios.post(`${baseUrl}/community/comment/create`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    if (resp.status === 200) {
      dispatch({ type: COMMENT_SUCCESS, payload: resp.data });
      dispatch({ type: COMMENT_LOADING, payload: false });
    }
    return resp;
  } catch (error: any) {
    dispatch({ type: COMMENT_LOADING, payload: false });
    dispatch({ type: COMMENT_ERROR, payload: error.data });
  }
};

export const likePost = (data: any, id: any) => async (dispatch: any) => {
  const token = loadtoken();
  dispatch({ type: LIKES_LOADING, payload: true });
  dispatch({ type: LIKES_ERROR, payload: null });

  try {
    const resp = await axios.post(
      `${baseUrl}/community/post/102/like-post`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (resp.status === 200) {
      dispatch({ type: LIKES_SUCCESS, payload: resp.data });
      dispatch({ type: LIKES_LOADING, payload: false });
    }
    return resp;
  } catch (error: any) {
    dispatch({ type: LIKES_LOADING, payload: false });
    dispatch({ type: LIKES_ERROR, payload: error.data });
  }
};
