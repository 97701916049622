import { Box, Text } from "@chakra-ui/react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";

interface Option {
  // Define the properties of your options here
  currency: string;
  // Other properties...
}

interface ResetOptionsAction {
  type: "resetOptions";
  value: Option;
}

type Dispatch<Action> = (action: Action) => void;

interface CreateOrderData {
  data: any; // You can refine this type based on the actual data structure
  actions: {
    order: {
      create: (orderDetails: any) => Promise<string>; // Specify the return type if known
    };
  };
}

interface ApproveOrderData {
  data: any; // Refine this type based on your requirements
  actions: {
    order: {
      capture: () => Promise<any>; // Specify the return type if known
    };
  };
}

export default function PaypalButtonComponent() {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);

  // const onCurrencyChange = ({ target: { value } }:  React.ChangeEvent<HTMLSelectElement>) => {
  //   setCurrency(value);
  //   dispatch({
  //     type: "resetOptions",
  //     value: {
  //       ...options,
  //       currency: value,
  //     },
  //   });
  // };

  const onCreateOrder = ({data, actions}: CreateOrderData) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "9.99",
          },
        },
      ],
    });
  };

  const onApproveOrder = ({data, actions}: ApproveOrderData) => {
    return actions.order.capture().then((details: any) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };


  return (
    <Box>
        <PayPalButtons 
          style={{ layout: "vertical" }}
          // createOrder={(data, actions) => onCreateOrder(data, actions)}
          // onApprove={(data, actions) => onApproveOrder(data, actions)}
         // createOrder={onCreateOrder}
        />
    </Box>
  );
}
