import { Box, Center, VStack, Text } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import MainNav from "../../../components/MainNav";

export default function NumerologyInsight() {
  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={'Numerology-Based Insight'} />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "90px", md: "90px" }}
      >
        {/* <SubNav
          title={"Numerology-Based Insight"}
          icon={<Icons type="plus" />}
          show={false}
          //onClick={onOpen}
        /> */}

        <Box>
          <Center paddingTop={1}>
            <Text fontWeight={"700"}> INTRODUCTION TO NUMEROLOGY</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>Basic Principles and Concepts: </Text>
            <Text>
              Numerology is the study of the mystical significance of numbers
              and their influence on human life and the world. Rooted in ancient
              civilizations such as Egypt, Greece, and China, numerology has
              evolved over centuries, integrating various cultural and
              philosophical insights.
            </Text>
            <Text>The Essence of Numbers: </Text>
            <Text>
              Each number from 1 to 9, along with master numbers like 11, 22,
              and 33, holds unique vibrations and attributes. For instance, the
              number 1 symbolizes leadership and independence, while the number
              7 is associated with introspection and spirituality.
            </Text>
            <Text>Numerology Charts and Grids: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Numerologists use charts and grids to map out numbers derived
                from personal data. These charts can reveal personality traits,
                life challenges, and potential paths.
              </Text>
            </Box>
            <Text>The Role of Patterns: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Numerology emphasizes patterns and cycles, such as the
                nine-year cycle, which suggests that life progresses through a
                repeating nine-year pattern, each year bringing different
                energies and opportunities.
              </Text>
            </Box>
            <Text>Historical Background and Applications: </Text>
            <>
              <Text>Ancient Origins:</Text>
              <Text>
                - The roots of numerology trace back to Pythagoras, the ancient
                Greek philosopher, who believed that numbers are the fundamental
                building blocks of the universe. His Pythagorean system is still
                widely used today.
              </Text>
            </>
            <>
              <Text>Cultural Integration:</Text>
              <Text>
                - Numerology has been adopted and adapted by various cultures,
                including the Chaldeans, who developed their system of number
                interpretation, and the Chinese, who use numerology in feng shui
                practices.
              </Text>
            </>
            <>
              <Text>Modern Applications:</Text>
              <Text>
                - Today, numerology is used for personal development,
                relationship insights, and even business decisions. Its
                applications in lottery predictions are a modern twist,
                leveraging the mystical insights of numerology to guide number
                selection.
              </Text>
            </>
          </VStack>
          <Center paddingTop={6}>
            <Text fontWeight={"700"}> CORE NUMEROLOGY NUMBERS</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>Key Numbers and Their Calculations: </Text>
            <Text>
              Numerology relies on several core numbers, each revealing
              different aspects of an individual’s life and personality. Here’s
              an in-depth look at these key numbers and how to calculate them:
            </Text>
            <>
              <Text>- Birth Number (Life Path Number): </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Calculation: Add the digits of your birth date until you
                  reach a single digit (e.g., 14/07/1985 → 1 + 4 + 0 + 7 + 1 + 9
                  + 8 + 5 = 35 → 3 + 5 = 8).
                  <br />
                  <br />
                  - Meaning: The Life Path Number represents your fundamental
                  character, challenges, and life purpose. For instance, a Life
                  Path Number 8 signifies ambition, power, and material success.
                  <br />
                  <br />
                  -Expression Number (Destiny Number):
                  <br />
                  <br />
                  - Calculation: Assign numerical values to the letters of your
                  full birth name and sum them up (e.g., JOHN DOE → J(1) + O(6)
                  + H(8) + N(5) + D(4) + O(6) + E(5) = 35 → 3 + 5 = 8).
                  <br />
                  <br />
                  Meaning: This number reflects your inherent talents,
                  abilities, and personal goals. It reveals your potential and
                  the path you are likely to follow in life.
                  <br />
                  <br />
                  - Soul Urge Number (Heart’s Desire Number):
                  <br />
                  <br />
                  - Calculation: Sum the numerical values of the vowels in your
                  full birth name (e.g., JOHN DOE → O(6) + O(6) + E(5) = 17 → 1
                  + 7 = 8).
                  <br />
                  <br />
                  - Meaning: The Soul Urge Number unveils your inner desires,
                  motivations, and what drives you on a deep, emotional level.
                  <br />
                  <br />
                  - Personality Number:
                  <br />
                  <br />- Calculation: Sum the numerical values of the
                  consonants in your full birth name (e.g., JOHN DOE → J(1) +
                  H(8) + N(5) + D(4) = 18 → 1 + 8 = 9).
                  <br />
                  <br />- Meaning: This number provides insight into how others
                  perceive you, your outward personality, and social
                  interactions.
                  <br />
                  <br />
                  To read more and have a better understanding of each of these
                  topics, visit the Basics of Numerology section.
                </Text>
              </Box>
            </>
          </VStack>
          <Center paddingTop={4}>
            <Text fontWeight={"700"}> APPLICATION IN LOTTERY PREDICTIONS</Text>
          </Center>
          <VStack alignItems={"flex-start"}>
            <Text>Using Numerology for Number Selection: </Text>
            <Text>
              Applying numerology to lottery predictions involves more than just
              understanding core numbers; it’s about using these insights to
              make informed choices. Here’s how numerology can guide your
              lottery number selection:
            </Text>

            <>
              <Text>- Aligning with Personal Numbers: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - By using your Life Path Number, Expression Number, and other
                  core numbers, you can select lottery numbers that resonate
                  with your personal vibrational patterns. For example, if your
                  Life Path Number is 5, you might favor numbers that add up to
                  5 or contain the digit 5.
                </Text>
              </Box>
            </>
            <>
              <Text>- Identifying Lucky Numbers: </Text>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Numerology can help identify your personal lucky numbers
                  based on your birth date and name. These numbers can be used
                  directly or as a basis for more complex combinations.
                  <br />
                  <br />- Timing and Cycles:
                </Text>
              </Box>
            </>
            <>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Numerology also considers the timing of events. Using your
                  personal year number (derived from your birth date and the
                  current year), you can determine the most favorable times for
                  making lottery predictions. For instance, a personal year 1
                  signifies new beginnings and might be a good time to start
                  playing new numbers.
                  <br />
                  <br />- Combining Insights:
                </Text>
              </Box>
            </>
            <>
              <Box paddingLeft={5} paddingBottom={4}>
                <Text>
                  - Effective use of numerology in lottery predictions involves
                  combining insights from different numbers. For instance, you
                  can use your Life Path Number for the primary selection, your
                  Expression Number for secondary choices, and integrate lucky
                  numbers derived from other numerology aspects.
                  <br />
                  <br />
                  - Practical Examples and Case Studies:
                  <br />
                  <br />- This section will showcase real-life examples and case
                  studies where individuals have successfully used numerology
                  for lottery predictions. These stories will provide practical
                  insights and inspiration, demonstrating how numerology can be
                  a powerful tool in selecting lottery numbers.
                </Text>
              </Box>
            </>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
}
