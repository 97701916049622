import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";
import SubNav from "../../components/SubNav";

export default function Privacy() {
  return (
    <Box paddingX={{ base: 4, md: "20px", lg: "150px" }} paddingBottom={"20px"}>
      {/* <DashboardNav /> */}
      {/* <MainNav title={"TERMS AND CONDITIONS"} /> */}
      <SubNav
        title={"TERMS AND CONDITIONS"}
        //   icon={<Icons type="plus" />}
        show={false}
        //onClick={onOpen}
      />
      <Box paddingTop={{ base: "100px", md: "100px" }} bgColor={"white"}>
        <Box>
          <Center>
            <VStack>
              <Text fontWeight={"700"}>TERMS AND CONDITIONS</Text>
            </VStack>
          </Center>
          <Box paddingTop={2}>
            Welcome to Win Wise. These terms and conditions outline the rules
            and regulations for the use of Win Wise's Website and Mobile
            Application.
            <br />
            <br />
            Acceptance of Terms:
            <br />
            <br />
            By accessing this website and/or using our mobile application, we
            assume you accept these terms and conditions in full. Do not
            continue to use Win Wise's website or mobile application if you do
            not accept all of the terms and conditions stated on this page.
            <br />
            <br />
            User Accounts:
            <br />
            <br />
            To access certain features, you may need to create an account. You
            are responsible for maintaining the confidentiality of your account
            information.
            <br />
            <br />
            Use of the Service:
            <br />
            <br />
            You agree to use the service only for lawful purposes and in
            accordance with these Terms. You agree not to use the service: - In
            any way that violates any applicable federal, state, local, or
            international law. - For exploiting, harming, or attempting to
            exploit or harm minors in any way. - To transmit, or procure the
            sending of, any advertising or promotional material without our
            prior written consent. Intellectual Property: The content, layout,
            design, data, databases, and graphics on this website and mobile
            application are protected by intellectual property laws. Reproducing
            any part of the site or app is prohibited without written
            permission. Termination: We may terminate or suspend your account
            and bar access to the Service immediately, without prior notice or
            liability, under our sole discretion, for any reason whatsoever and
            without limitation, including but not limited to a breach of the
            Terms. Governing Law: These terms shall be governed and construed by
            the laws of Curacao, without regard to its conflict of law
            provisions. Changes to Terms: We reserve the right to modify or
            replace these Terms at any time. Your continued use of the site or
            app after any such changes constitutes your acceptance of the new
            Terms. If you have any questions about these Terms, don't hesitate
            to get in touch with us at [email].
          </Box>
          <Center paddingTop={"20px"}>
            <VStack>
              <Text fontWeight={"700"}>PRIVACY POLICY</Text>
            </VStack>
          </Center>
          <Box paddingTop={2}>
            At Win Wise, we value your privacy and are committed to protecting
            your personal information. This Privacy Policy outlines how we
            collect, use, and safeguard your data.
            <br />
            <br />
            Information We Collect: -
            <br />
            <br />
            Personal data (name, email, etc.) when you register or subscribe. -
            Usage data (IP address, browser type, etc.) for analytics. How We
            Use Your Information:
            <br />
            <br />
            - To provide and improve our services.
            <br />
            - To communicate with you about updates and promotions.
            <br />
            - To personalize your experience on our platform.
            <br />
            <br />
            Data Security: We implement robust security measures to protect your
            data from unauthorized access. Third-Party Services: We may share
            your data with trusted third parties for analytics and payment
            processing.
            <br />
            <br />
            Your Rights:
            <br />
            You have the right to access, update, or delete your personal
            information. Contact us at [email] for any privacy-related queries.
            Changes to This Policy: We may update this policy periodically.
            Changes will be posted on this page. By using our services, you
            agree to this Privacy Policy.
          </Box>
          <Box paddingTop={2}>
            <Center paddingTop={"20px"}>
              <VStack>
                <Text fontWeight={"700"}>DISCLAIMER</Text>
              </VStack>
            </Center>
            <Box paddingTop={2}>
              Disclaimer for Win Wise The information provided by Win Wise
              (“we,” “us,” or “our”) on [winwise.com] (the “Site”) and our
              mobile application is for general informational purposes only. All
              information on the Site and our mobile application is provided in
              good faith, however, we make no representation or warranty of any
              kind, express or implied, regarding the accuracy, adequacy,
              validity, reliability, availability, or completeness of any
              information on the Site or our mobile application. Professional
              Advice: The dream interpretations and numerology predictions
              provided are for entertainment purposes only and should not be
              considered as professional advice. Always seek the advice of a
              qualified professional with any questions you may have regarding a
              medical condition, financial advice, or other professional
              matters. Liability: Under no circumstance shall we have any
              liability to you for any loss or damage of any kind incurred as a
              result of the use of the site or our mobile application or
              reliance on any information provided on the site and our mobile
              application. External Links: The Site and our mobile application
              may contain (or you may be sent through the Site or our mobile
              application) links to other websites or content belonging to or
              originating from third parties. Such external links are not
              investigated, monitored, or checked for accuracy, adequacy,
              validity, reliability, availability, or completeness by us.
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
