import { Box, Center, Image, Textarea } from "@chakra-ui/react";
import React from "react";
import Girl from "../../assets/Girl.svg";
import { InputField } from "../../components/InputField";
import Icons from "../../assets/Icons";
import { colors } from "../../theme/colors";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import MainNav from "../../components/MainNav";

export default function SixDigitDreamInterpretation() {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  return (
    <Box bgColor={"white"} height={"100vh"}>
      {/* <DashboardNav /> */}
      <MainNav title={`${title}`} />
      <Box paddingX={{ base: "20px", md: "11%" }}>
        {/* <SubNav title={`${title}`} /> */}
        <Box paddingTop={{ base: "10px", md: "15px" }}>
          <Box
            bgColor={"#DBDBDB"}
            padding={{ base: 7, md: 9 }}
            borderRadius={10}
          >
            <Center>
              <Image src={Girl} />
            </Center>
          </Box>
          <Center>
          <Box
            paddingTop={5}
            width={{ base: "100%", md: "40%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* <InputField
              onChange={(e: any) => {
                // setUsername(e.target.value);
              }}
              placeholder="Numbers to be generated"
              // value={username}
              width={"100%"}
              height={"48px"}
              color={"black"}
            /> */}
            <InputField
              onChange={(e: any) => {
                // setUsername(e.target.value);
              }}
              placeholder="Birthday date:"
              // value={username}
              width={"100%"}
              height={"48px"}
              color={"black"}
            />
            <Textarea
              placeholder="Describe your dream"
              width={"100%"}
              //height={"48px"}
              color={"black"}
              borderRadius={20}
              // type="em"
              // value={convertToLowerCase(email)}
              onChange={(e: any) => {
                // setEmail(e.target.value);
              }}
            />
            <Box paddingY={5}>
            <Button
              onClick={async () => {
                navigation("/six-digit-dream-interpretation-lottery");
              }}
              label={"GENERATE NUMBERS"}
              bgColor={colors.primary}
              width={{ base: "100%", md: "100%" }}
              borderRadius={"33px"}
              height={"48px"}
              color="white"
              fontWeight={"300"}
              paddingX={4}
              fontSize={16}
              
              icon={<Icons type="forward" />}
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            />
            </Box>
          </Box>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}
