import {
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_LOADING,
  NEW_PASSWORD_SUCCESS,
  PROFILE_ERROR,
  PROFILE_LOADING,
  PROFILE_SUCCESS,
  REGISTER_ERROR,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  VERIFY_ERROR,
  VERIFY_LOADING,
  VERIFY_SUCCESS,
} from "../reducers/types";
import axios from "axios";
import Request from "../../api/request";
import { loadtoken } from "../token";

const baseUrl = Request.url;
const token = loadtoken();
// console.log(token, "console token")

export const fireLogin = (data: any) => async (dispatch: any) => {
  dispatch({ type: LOGIN_LOADING, payload: true });
  dispatch({ type: LOGIN_ERROR, payload: null });

  try {
    const resp = await axios.post(`${baseUrl}/accounts/login`, data);
    console.log(resp, "line 34")

    dispatch({ type: LOGIN_LOADING, payload: false });
    if (resp.status === 200) {
      dispatch({ type: LOGIN_SUCCESS, payload: resp.data });
      // console.log(resp, "logged in");
    }

    return resp;
  } catch (error: any) {
    dispatch({ type: LOGIN_ERROR, payload: error.data });
    dispatch({ type: LOGIN_LOADING, payload: false });
  }
};

export const fireRegister = (data: any) => async (dispatch: any) => {
  dispatch({ type: REGISTER_LOADING, payload: true });
  dispatch({ type: REGISTER_ERROR, payload: null });

  try {
    const res = await axios.post(`${baseUrl}/accounts/register`, data);
    console.log(res, "response");
    if (res.status === 201) {
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      dispatch({ type: REGISTER_LOADING, payload: false });
    }
    return res;
  } catch (error: any) {
    dispatch({ type: REGISTER_ERROR, payload: error.data });
    dispatch({ type: REGISTER_LOADING, payload: false });
  }
};

export const verifyAccount = (data: any) => async (dispatch: any) => {
  dispatch({ type: VERIFY_LOADING, payload: true });
  dispatch({ type: VERIFY_ERROR, payload: null });

  try {
    const res = await axios.post(`${baseUrl}/accounts/verify-phone`, data);
    console.log(res, "response");
    if (res.status === 200) {
      dispatch({ type: VERIFY_SUCCESS, payload: res.data });
      dispatch({ type: VERIFY_LOADING, payload: false });
    }
    return res;
  } catch (error: any) {
    dispatch({ type: VERIFY_ERROR, payload: error.data });
    dispatch({ type: VERIFY_LOADING, payload: false });
  }
};

export const saveProfile = (data: any) => async (dispatch: any) => {
  dispatch({ type: PROFILE_LOADING, payload: true });
  dispatch({ type: PROFILE_ERROR, payload: null });
  // dispatch({ type: PROFILE_SUCCESS, payload: true})
  try {
    const res = await axios.patch(`${baseUrl}/accounts/save-profile`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      dispatch({ type: PROFILE_SUCCESS, payload: res.data });
      dispatch({ type: PROFILE_LOADING, payload: false });
    }
    return res;
  } catch (error: any) {
    dispatch({ type: PROFILE_LOADING, payload: false });
    dispatch({ type: PROFILE_ERROR, payload: error.data });
  }
};

export const forgotPassword = (data: any) => async (dispatch: any) => {
  dispatch({ type: FORGOT_PASSWORD_LOADING, payload: true });
  dispatch({ type: FORGOT_PASSWORD_ERROR, payload: null });

  try {
    const res = await axios.post(`${baseUrl}/accounts/forgot-password`, data);
    console.log(res, "response");
    if (res.status === 200) {
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: res.data });
      dispatch({ type: FORGOT_PASSWORD_LOADING, payload: false });
    }
    return res;
  } catch (error: any) {
    dispatch({ type: FORGOT_PASSWORD_ERROR, payload: error.data });
    dispatch({ type: FORGOT_PASSWORD_LOADING, payload: false });
  }
};

export const newPassword = (data: any) => async (dispatch: any) => {
  const token = loadtoken();
  dispatch({ type: NEW_PASSWORD_LOADING, payload: true });
  dispatch({ type: NEW_PASSWORD_ERROR, payload: null });

  try {
    const res = await axios.post(`${baseUrl}/accounts/change-password`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    console.log(res, "response");
    if (res.status === 200) {
      dispatch({ type: NEW_PASSWORD_SUCCESS, payload: res.data });
      dispatch({ type: NEW_PASSWORD_LOADING, payload: false });
    }
    return res;
  } catch (error: any) {
    dispatch({ type: NEW_PASSWORD_ERROR, payload: error.data });
    dispatch({ type: NEW_PASSWORD_LOADING, payload: false });
  }
};
