import { Box, Text, Image, Center, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import SubNav from "../../components/SubNav";
import HomeNavbar from "../../components/HomeNavbar";
import DashboardNav from "../../components/DashboardNav";
import NumPred from "../../assets/NumberPred.svg";
import Card1 from "../../assets/card1.svg";
import Icons from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import MainNav from "../../components/MainNav";

interface CardProps {
  title: string;
  image: string;
  numbers: string;
  path: string;
}

export default function DreamsInterpretationScreens() {
  const navigation = useNavigate();

  const cardItems: CardProps[] = [
    {
      title: "Wega di Number Kòrsou ",
      numbers: "04 numbers",
      image: Card1,
      path: "/dream-interpretation",
    },
    {
      title: "Wega di Number Bonaire",
      numbers: "04 numbers",
      image: Card1,
      path: "/dream-interpretation",
    },
    {
      title: "Mega",
      numbers: "06 numbers",
      image: Card1,
      path: "/six-digit-dream-screen",
    },
    {
      title: "Power",
      numbers: "06 numbers",
      image: Card1,
      path: "/six-digit-dream-screen",
    },
    {
      title: "Lottery Name",
      numbers: "06 numbers",
      image: Card1,
      path: "/dream-dictionary",
    },
  ];
  
  return (
    <Box bgColor={"white"} height={"100vh"}>
      {/* <DashboardNav /> */}
      <MainNav title={"Dream Interpretations"} />
      <Box paddingX={{ base: "20px", md: "11%" }}>
        {/* <SubNav title={"Dream Interpretations"} /> */}
        <Box paddingTop={{ base: "100px", md: "100px" }}>
          <Box
            bgColor={"#DBDBDB"}
            padding={{ base: 7, md: 9 }}
            borderRadius={10}
          >
            <Center>
              <Image src={NumPred} />
            </Center>
          </Box>
        </Box>
        <HStack
          flexWrap={"wrap"}
          display={"flex"}
          justifyContent={"space-between"}
          paddingBottom={"20px"}
        >
          {cardItems.map((items, id) => {
            return (
              <Box
                display={"flex"}
                flexDir={{ base: "row", md: "row" }}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={{ base: "100%", md: "40%" }}
                borderBottomWidth={1}
                paddingY={4}
                onClick={() => {
                  //navigation(`${items.path}`);
                  navigation(
                    `${items.path}?title=${encodeURIComponent(items.title)}`
                  );
                }}
              >
                <HStack width={"100%"}>
                  <Image src={items.image} />
                  <VStack
                    lineHeight={"15px"}
                    paddingLeft={"0px"}
                    alignItems={"flex-start"}
                  >
                    <Text fontWeight={"600"}>{items.title}</Text>
                    <Text fontSize={12}>{items.numbers}</Text>
                  </VStack>
                </HStack>
                <Icons type={"forward-grouped"} width={20} height={20} />
              </Box>
            );
          })}
        </HStack>
      </Box>
    </Box>
  );
}
