import { Box, Center, VStack, Text } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import MainNav from "../../../components/MainNav";

export default function PredictiveAnalysis() {
  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={"Predictive Analytics"} />
      <Box paddingX={{ base: 4, md: "20px", lg: "150px" }} paddingTop={"100px"}>
        {/* <SubNav
          title={"Predictive Analytics"}
          icon={<Icons type="plus" />}
          show={false}
          //onClick={onOpen}
        /> */}
        <Center>
          <VStack>
            <Text fontWeight={"700"} color={"red"} textAlign={"center"}>
              PREDICTIVE ANALYTICS FOR LOTTERY NUMBER
            </Text>
          </VStack>
        </Center>
        <Box>
          <VStack alignItems={"flex-start"}>
            <Text>Description: </Text>
            <Text>
              Learn about predictive analytics and how it can be used to enhance
              lottery predictions by analyzing historical data. Predictive
              analytics is a powerful tool that leverages historical data and
              advanced algorithms to forecast future events. In the context of
              lottery predictions, predictive analytics can help users identify
              patterns and trends in past lottery results, thereby improving
              their chances of selecting winning numbers.
            </Text>
          </VStack>
          <Box paddingTop={4}>
            <Text fontWeight={"700"}>
              -Introduction to Predictive Analytics:
            </Text>
          </Box>
          <VStack alignItems={"flex-start"}>
            <Text>- What is Predictive Analytics? </Text>
            <Text>
              Predictive analytics involves the use of statistical techniques,
              machine learning algorithms, and data mining to analyze historical
              data and make predictions about future outcomes. It is widely used
              in various industries, including finance, healthcare, and
              marketing, to drive decision-making and strategy.
            </Text>
            <Text>
              {" "}
              - Importance of Historical Data Analysis in Lottery Predictions:{" "}
            </Text>
            <Text>
              Historical data analysis is crucial in lottery predictions because
              it helps identify recurring patterns and trends. By understanding
              how past lottery draws have unfolded, users can make more informed
              choices about their number selections.
            </Text>
            <Text fontWeight={"700"}>- How Predictive Analytics Works: </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text fontWeight={"600"}>- Overview of Algorithms Used:</Text>
              <Text>
                Predictive analytics relies on a range of algorithms, including
                regression analysis, time series analysis, and neural networks.
                These algorithms analyze historical lottery data to identify
                patterns and generate predictions.
                <br />
                <br />
                - Regression Analysis:
                <br />
                This algorithm helps identify relationships between variables in
                lottery data, such as the frequency of specific numbers.
                <br />
                - Time Series Analysis:
                <br />
                This algorithm examines data points collected over time to
                identify trends and patterns.
                <br />- Neural Networks: These algorithms mimic the human
                brain's functioning to recognize complex patterns in data.
              </Text>
              <Text fontWeight={"600"} paddingTop={3}>
                {" "}
                - Key Factors and Metrics Analyzed:
              </Text>
              <Text>
                {" "}
                Several factors and metrics are analyzed in predictive analytics
                for lottery predictions, including:
                <br />
                - Frequency Analysis: Examining how often specific numbers have
                appeared in past draws.
                <br />- Hot and Cold Numbers: Identifying numbers that
                frequently appear (hot) and those that rarely appear (cold). -
                Number Pairs and Sequences: Analyzing common pairs and sequences
                of numbers that tend to appear together.
              </Text>
              <Text fontWeight={"600"} paddingTop={3}>
                {" "}
                - Using Predictive Analytics in the App:
              </Text>
              <Text>
                - How to Select Specific Lotteries: The Win Wise app allows
                users to select specific lotteries they are interested in. Users
                can choose from a range of lottery games and access tailored
                predictive analytics for their selected games.
                <br />
                - Personalized Number Suggestions Based on Data:
                <br />
                The app provides personalized number suggestions based on
                historical data analysis. By inputting user preferences and
                analyzing past lottery results, the app generates number
                combinations that have a higher likelihood of appearing in
                future draws. Users can customize their settings to receive the
                most relevant predictions for their chosen lotteries.
                <Text fontWeight={"600"}>
                  To read more and gain a deeper understanding, visit the Basics
                  of Numerology section, where we delve into the principles
                  behind predictive analytics and other essential numerology
                  concepts.
                </Text>
              </Text>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
}
