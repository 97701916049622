import { Box, Center, HStack, Text, Image } from "@chakra-ui/react";
import React from "react";
import Icons from "../../assets/Icons";

interface CardProps {
    id: string;
    title: string;
    label: string;
    image: string;
    icon: string;
    onClick: () => void;
}

export default function Card({ id,
    title,
    label,
    onClick,
    icon,
    image }: CardProps) {
    return (
        <Box
            height={{base: '50vh', md: '55vh'}}
            bgColor={'#F8F8F8'}
            borderRadius={'20px'}
            width={{base: '100%', md: '100%', lg: '30%'}}
            paddingX={'10px'} paddingTop={'10px'}
            onClick={onClick}
        >
            <HStack justifyContent={'space-between'}>
                <Text fontSize={{base: 18, md: 14, lg: 22}} width={'50%'}>{title}</Text>
                <div>
                    <Icons type="forward-container" />
                </div>
            </HStack>
            <Text fontSize={12} width={"75%"}>{label}</Text>
            <Center paddingTop={1}>
                <Image src={image} alt={"card"} width={{base: 130, lg: 150}} />
            </Center>
        </Box>
    )
}