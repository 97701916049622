import React, { useState } from 'react';
import { Box, VStack, Input, Button, Text } from '@chakra-ui/react';
import { colors } from '../../theme/colors';

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
}

const ChatUI: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;

    // Add user message
    const newMessage: Message = {
      id: messages.length + 1,
      text: inputValue,
      sender: 'user',
    };
    setMessages([...messages, newMessage]);

    // Simulate bot reply
    setTimeout(() => {
      const botReply: Message = {
        id: newMessage.id + 1,
        text: `You said: ${inputValue}`,
        sender: 'bot',
      };
      setMessages((prevMessages) => [...prevMessages, botReply]);
    }, 1000);

    setInputValue('');
  };

  return (
    <Box
      maxWidth={{ base: '100%', md: '400px' }}
      height={{ base: '100vh', md: '600px' }}
      border="1px solid #ccc"
      borderRadius="md"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      bg="white"
    >
      {/* Message List */}
      <VStack
        flex="1"
        overflowY="auto"
        padding="4"
        spacing="4"
        align="stretch"
        bg="gray.50"
      >
        {messages.map((message) => (
          <Box
            key={message.id}
            alignSelf={message.sender === 'user' ? 'flex-end' : 'flex-start'}
            bg={message.sender === 'user' ? 'blue.100' : 'gray.200'}
            p="2"
            borderRadius="md"
            maxWidth="70%"
          >
            <Text>{message.text}</Text>
          </Box>
        ))}
      </VStack>

      {/* Input Field */}
      <Box display="flex" p="2" bg="white" borderTop="1px solid #ccc">
        <Input
          placeholder="Type your message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          flex="1"
        />
        <Button
          ml="2"
          bgColor={colors.primary}
          onClick={handleSendMessage}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default ChatUI;