import { Box, Center, Text, useToast, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { colors } from "../../theme/colors";
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import Icons from "../../assets/Icons";
import SubNav from "../../components/SubNav";
import { connect } from "react-redux";
import { newPassword } from "../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import { savetoken } from "../../redux/token";

function NewPassword({
  newPasswordLoading,
  newPasswordSuccess,
  newPasswordError,
  callNewPasswordRequest,
}: any) {
  const toast = useToast();
  const navigation = useNavigate();
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");

  return (
    <Box paddingX={{ base: "20px", md: "15%" }} bgColor={"white"}>
      <SubNav title={"New Password"} />
      <Box
        //width={'full'}
        bgColor={"white"}
        height={"100vh"}
        //alignItems={'center'}
        paddingX={{ base: "20px", md: "30%" }}
      >
        <Center paddingTop={"100px"}>
          <VStack width={"100%"}>
            <Box
              width={"92px"}
              height={"92px"}
              borderRadius={"100%"}
              bgColor={"white"}
              paddingTop={"5px"}
              paddingLeft={"5px"}
              borderWidth={1}
              shadow={"md"}
              marginBottom={2}
            >
              <Box
                width={"80px"}
                height={"80px"}
                borderRadius={"100%"}
                bgColor={"#CFD9E2"}
                paddingTop={"15px"}
                paddingX={"20px"}
                borderWidth={1}
                shadow={"md"}
              >
                <Icons type="padlock" />
              </Box>
            </Box>
            <Text paddingBottom={2}>Enter your new password</Text>

            <InputField
              onChange={(e: any) => {
                setCurrentPassword(e.target.value);
              }}
              placeholder="Current Password"
              width={"100%"}
              height={"48px"}
              color={"black"}
              value={current_password}
            />
            <InputField
              onChange={(e: any) => {
                setNewPassword(e.target.value);
              }}
              placeholder="New Password"
              width={"100%"}
              height={"48px"}
              value={new_password}
              color={'black'}
            />
            <Box width={"100%"}>
              <Button
                onClick={async () => {
                  const res = await callNewPasswordRequest({
                    current_password,
                    new_password,
                  });
                  if (res.status === 200) {
                    console.log(newPasswordSuccess);
                    navigation("/dashboard");
                    // localStorage.setItem("access-token", res?.data?.access)
                    savetoken(res?.data?.access); toast({
                      title: "success",
                      isClosable: true,
                      description: "password changed successfully",
                      duration: 2000,
                      status: "success",
                      position: "top",
                    });

                  } else {
                    console.log(newPasswordError);
                    // console.log(res);
                    toast({
                      title: "error",
                      isClosable: true,
                      description: "invalid credentials or network problem",
                      duration: 2000,
                      status: "error",
                      position: "top",
                    });
                  }
                }}
                loading={newPasswordLoading}
                label={"SAVE PASSWORD"}
                bgColor={colors.primary}
                // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                width={{ base: "100%", md: "100%" }}
                borderRadius={"33px"}
                height={"48px"}
                color="white"
                fontWeight={"300"}
                paddingX={4}
                fontSize={16}
                icon={<Icons type="forward" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              />
            </Box>
          </VStack>
        </Center>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  newPassordLoading: state.auth.newPassordLoading,
  newPassordSuccess: state.auth.newPassordSuccess,
  newPassordError: state.auth.newPassordError,
});

const mapDispatch = (dispatch: any) => ({
  callNewPasswordRequest: (data: any) => dispatch(newPassword(data)),
});

export default connect(mapState, mapDispatch)(NewPassword);
