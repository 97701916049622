import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { loadtoken } from "../redux/token";

export default function PrivateRoute ({auth}: any) {
   return loadtoken() ? (
    <Outlet />
   ) : (
    <Navigate to="/login" />
   )
}