import { Box, Center, Text, Image, HStack, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DashboardNav from "../../components/DashboardNav";
import SubNav from "../../components/SubNav";
import NumPred from "../../assets/NumberPred.svg";
import Button from "../../components/Button";
import { colors } from "../../theme/colors";
import Icons from "../../assets/Icons";
import Request from "../../api/request";
import { loadtoken } from "../../redux/token";
import axios from "axios";
import { useLocation } from "react-router-dom";
import MainNav from "../../components/MainNav";

export default function LotteryGame() {
  const baseUrl = Request.url;
  const [data, setData] = useState(["00", "00", "00", "00"]);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(getData, "getData");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");

  const handleGame = () => {
    const token = loadtoken();
    console.log(token, "get, unique string");
    setLoading(true);

    let code = "";

    switch (title) {
      case "Wega di Number Kòrsou":
        code = "wc";
        break;
      case "Wega di Number Bonaire":
        code = "wb";
        break;
      default:
        code = "wc";
        break;
    }

    axios
      .get(`${baseUrl}/predictor/predict?code=${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(response.data.number);
        console.log(response.data.number, "numbers");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const getDataArr = () => {
      const token = loadtoken();
      setLoading(true);
      axios
        .get(`${baseUrl}/predictor/get-lotteries`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          setGetData(response.data);
          console.log(response.data, "numbers");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    getDataArr();
  }, []);

  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={`${title}`}/>
      <Box
        paddingX={{ base: "20px", md: "11%" }}
        // paddingTop={{ base: "65px", md: "100px" }}
      >
        {/* <SubNav title={`${title}`} /> */}
        <Box paddingTop={{ base: "90px", md: "90px" }}>
          <Box
            bgColor={"#DBDBDB"}
            padding={{ base: 7, md: 9 }}
            borderRadius={10}
          >
            <Center>
              <Image src={NumPred} />
            </Center>
            <VStack
              width={{ base: "100%", md: "100%", lg: "100%" }}
              paddingTop={3}
              paddingLeft={{ base: 0, md: "6px", lg: "26px" }}
            >
              <HStack
                justifyContent={"space-between"}
                width={{ base: "100%", md: "50%", lg: "30%" }}
              >
                <Text fontSize={{ base: 12, md: 12, lg: 16 }}>
                  Predicted Number
                </Text>
                <Button
                  onClick={handleGame}
                  loading={loading}
                  label={"Generate new"}
                  bgColor={colors.primary}
                  // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                  width={{ base: "50%", md: "45%" }}
                  borderRadius={"33px"}
                  height={"30px"}
                  color="white"
                  fontWeight={"300"}
                  paddingX={4}
                  fontSize={12}
                  icon={""}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  hasIcon={true}
                />
              </HStack>
              <HStack
                display="flex"
                justifyContent={"space-between"}
                paddingY={2}
                alignContent={"center"}
              >
                {data.slice(0, 4).map((item: any, index: number) => (
                  <Box
                    key={index}
                    bgColor={"#4253B7"}
                    padding={5}
                    borderRadius={8}
                  >
                    <Text color={"white"}>{item}</Text>
                  </Box>
                ))}
              </HStack>
            </VStack>
          </Box>
          <Box paddingTop={10}>
            <HStack>
              <Box
                width={{ base: "27.5%", md: "38.5%", lg: "43.5%" }}
                borderWidth={2}
                borderColor={"#009688"}
                opacity={0.2}
              />
              <Text color={"#009688"}>Past Generations</Text>
              <Box
                width={{ base: "27.5%", md: "38.5%", lg: "43.5%" }}
                borderWidth={2}
                borderColor={"#009688"}
                opacity={0.2}
              />
            </HStack>
            <Center>
              <VStack width={"100%"}>
                <HStack
                  justifyContent={"space-between"}
                  width={{ base: "100%", md: "70%", lg: "40%" }}
                >
                  <Text>Contest #3467</Text>
                  <Box>Nov 24, 2023</Box>
                </HStack>
                {getData &&
                  getData.length > 0 &&
                  getData?.slice(0, 5)?.map((eachObj: any, index) => {
                    return (
                      <HStack
                        key={index}
                        display="flex"
                        justifyContent={"space-between"}
                        width={{ base: "100%", md: "70%", lg: "40%" }}
                        paddingY={6}
                        alignContent={"center"}
                        borderBottomWidth={"2px"}
                        marginBottom={2}
                      >
                        {JSON.parse(eachObj?.prediction)
                          .slice(0, 4)
                          .map((eachNumber: any, index: number) => {
                            return (
                              <Box
                                bgColor={"#DBDBDB"}
                                padding={{base: 5, md: 5}}
                                borderRadius={8}
                                key={index}
                              >
                                <Text color={"black"}>{eachNumber}</Text>
                              </Box>
                            );
                          })}
                      </HStack>
                    );
                  })}
              </VStack>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
