import { Box, Center, Text, Image, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import Girl from "../../assets/Girl.svg";
import { InputField } from "../../components/InputField";
import Icons from "../../assets/Icons";
import { colors } from "../../theme/colors";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { generate_dream_interpretation } from "../../redux/actions/gamesAction";
import MainNav from "../../components/MainNav";

function DreamInterpretation({
  dreamInterpretationLoading,
  dreamInterpretationSuccess,
  dreamInterpretationError,
  generateGameRequest,
}: any) {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const [expected_number] = useState(4)
  const [expected_numbers, setExpectedNumbers] = useState({
    //expected_numbers: 4,
    value: "",
    birthday: ""
  });

  return (
    <Box bgColor={"white"} height={"100vh"}>
      {/* <DashboardNav /> */}
      <MainNav title={`${title}`} />
      <Box
        paddingX={{ base: "20px", md: "11%" }}
        // paddingTop={{ base: "65px", md: "100px" }}
      >
        {/* <SubNav title={`${title}`} /> */}
        <Box paddingTop={{ base: "90px", md: "90px" }}>
          <Box
            bgColor={"#DBDBDB"}
            padding={{ base: 7, md: 9 }}
            borderRadius={10}
          >
            <Center>
              <Image src={Girl} />
            </Center>
          </Box>
          <Center>
            <Box
              paddingTop={5}
              width={{ base: "100%", md: "40%" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {/* <InputField
                onChange={(e: any) => {
                  setExpectedNumber(e.target.value);
                }}
                placeholder="Birthday date:"
                value={expected_number.expected_numbers}
                width={"100%"}
                height={"48px"}
                color={"black"}
              /> */}
              <InputField
                onChange={(e: any) => {
                  setExpectedNumbers(e.target.value);
                }}
                placeholder="Birthday date:"
                //value={expected_number.birthday}
                width={"100%"}
                height={"48px"}
                color={"black"}
              />
              <Textarea
                placeholder="Describe your dream"
                width={"100%"}
                //height={"48px"}
                color={"black"}
                borderRadius={20}
                // type="em"
                // value={expected_number.value}
                onChange={(e: any) => {
                  setExpectedNumbers(e.target.value);
                }}
              />
              <Box paddingY={5}>
                <Button
                  onClick={async () => {
                    // console.log(text)
                    const res = await generateGameRequest({
                      expected_number,
                    });
                    console.log(res?.data?.number, "dream success");
                    if (res.status === 200) {
                      // console.log(dreamInterpretationSuccess, "dreams");
                      navigation("/dream-screen", {
                        state: { number: res?.data?.number },
                      });
                      // localStorage.setItem("access-token", res?.data?.access)
                    } else {
                      console.log(dreamInterpretationError, "dreams error");
                      // console.log(res);
                    }
                  }}
                  loading={dreamInterpretationLoading}
                  label={"GENERATE NUMBERS"}
                  bgColor={colors.primary}
                  width={{ base: "100%", md: "100%" }}
                  borderRadius={"33px"}
                  height={"48px"}
                  color="white"
                  fontWeight={"300"}
                  paddingX={4}
                  fontSize={16}
                  icon={<Icons type="forward" />}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                />
              </Box>
            </Box>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  dreamInterpretationLoading: state.auth.dreamInterpretationLoading,
  dreamInterpretationSuccess: state.auth.dreamInterpretationSuccess,
  dreamInterpretationError: state.auth.dreamInterpretationError,
});

const mapDispatch = (dispatch: any) => ({
  generateGameRequest: (data: any) =>
    dispatch(generate_dream_interpretation(data)),
});

export default connect(mapState, mapDispatch)(DreamInterpretation);
