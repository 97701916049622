import authReducer from "./authReducer"
import gameReducers from "./gameReducer"
import postReducers from "./postReducers"

const rootReducer = {
    auth: authReducer,
    posts: postReducers,
    games: gameReducers, 

}

export default rootReducer