// import React, { useState } from "react";
import {
  Box,
  // Text,
  Image,
  // VStack,
  //  HStack,
  Show,
  Hide,
  Text,
  //HStack,
} from "@chakra-ui/react";
import Logo2 from "../assets/Logo2.svg";
// import theme from "../theme";
// import Button from "./Button";
// import { colors } from "../theme/colors";
import Icons from "../assets/Icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../theme/colors";
import { loadtoken } from "../redux/token";
import Request from "../api/request";
import axios from "axios";

// interface NavProps {
//     id: string;
//     label: string;
//     path?: string;
// }

export default function DashboardNav() {
  const [openHamburger, setOpenHamburger] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [select, setSelect] = useState("0");
  const navigation = useNavigate();
  const baseUrl = Request.url;

  const toggleHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  useEffect(() => {
    const getUserData = async () => {
      const token = loadtoken();
      console.log(token, "get, unique string");
      axios
        .get(`${baseUrl}/accounts/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response.data, "lol");
          const data = response.data;
          const { get_profile_image } = data;
          setImage(get_profile_image);
          console.log(get_profile_image, "image");
          //setUser(data);
          //setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUserData();
  }, [baseUrl]);

  type NavProps = {
    label: string;
    path?: string;
    id: string;
  };

  const navItems: NavProps[] = [
    {
      label: "Home",
      path: '/dashboard',
      id: "0",
    },
    {
      label: "Saved",
      path: '/saved-numbers',
      id: "1",
    },
    {
      label: "Subscriptions",
      path: '/subscriptions',
      id: "2",
    },
    {
      label: "Profile",
      path: "/profile",
      id: "3",
    },
    {
      label: "Notifications",
      path: '/notifications',
      id: "4",
    },
    {
      label: "Settings",
      path: '/settings',
      id: "5",
    },
    {
      label: "Logout",
      //path: '',
      id: "6",
    },
  ];

  return (
    <>
      <Hide breakpoint="(max-width: 768px)">
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingX={"150px"}
          bgColor={"#263238"}
          height={"100px"}
          borderBottomWidth={1}
          position={'fixed'}
          width={'100%'}
          //shadow={"sm"}
        >
          <Box as="button" onClick={toggleHamburger}>
            <Icons type={"hamburger"} color="white" />
          </Box>
          <Box
            display={"flex"}
            flexDir={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            //width={"40%"}
            as="button"
          >
            <Image src={Logo2} alt={"logo"} />
          </Box>
          <Box style={{ borderRadius: 40 / 2, width: 40, height: 40 }}>
            {image ? (
              <Image
                src={image}
                alt="Profile"
                // width="40px"
                // height="40px"
                style={{ borderRadius: 40 / 2, width: 40, height: 40 }}
              />
            ) : (
              <Box
                width={"40px"}
                height={"40px"}
                borderRadius={"100%"}
                borderWidth={1}
                bgColor={"white"}
              ></Box>
            )}
          </Box>
        </Box>
      </Hide>
      <Show breakpoint="(max-width: 768px)">
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"space-between"}
          paddingX={"20px"}
          alignItems={"center"}
          bgColor={"#263238"}
          height={"65px"}
          borderBottomWidth={1}
          shadow={"sm"}
          onClick={toggleHamburger}
          position={'fixed'}
          width={'100%'}
        >
          <Icons type="hamburger" color={"white"} />
          <Box>
            <Image src={Logo2} alt={"logo"} height={10} />
          </Box>
          <Box>
            {image ? (
              <Image
                src={image}
                width={"40px"}
                height={"40px"}
                borderRadius={"100%"}
              />
            ) : (
              <Box
                width={"40px"}
                height={"40px"}
                borderRadius={"100%"}
                borderWidth={1}
                bgColor={"white"}
              ></Box>
            )}
          </Box>
        </Box>
      </Show>
      {openHamburger ? (
        <Box
          position={"fixed"}
          bgColor={"#263238"}
          width={"full"}
          height={"full"}
          top={0}
          alignItems={"center"}
          display={"flex"}
          flexDir={"column"}
          paddingTop={{ base: 4, md: 10 }}
          zIndex={"1000"}
        >
          <Box
            alignItems={"center"}
            display={"flex"}
            flexDir={"column"}
            paddingTop={{ base: "70px", md: "100px" }}
          >
            {navItems.map((items, id) => {
              return (
                <>
                  <Box
                    onClick={() => {
                      setSelect(items.id);
                    }}
                    key={id}
                  >
                    <Text
                      color={select === items.id ? colors.primary : "white"}
                      fontSize={{ base: 20, md: 26 }}
                      fontWeight={"600"}
                      as={"button"}
                      onClick={() => navigation(`${items.path}`)}
                    >
                      {items.label}
                    </Text>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box
            position={"absolute"}
            right={4}
            as="button"
            onClick={toggleHamburger}
          >
            <Icons type="close" color="white" />
          </Box>
        </Box>
      ) : null}
    </>
  );
}
