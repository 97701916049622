import { Box, Hide, HStack, Show, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DashboardNav from "../../components/DashboardNav";
import Icons from "../../assets/Icons";
import FreeTier from "./free-tier";
import MonthlyTier from "./monthly-tier";
import YearlyTier from "./yearly-tier";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import PayPalButton from "../paypal";

function Subscriptions() {
  const [select, setSelect] = useState("0");
  const [{ options }, dispatch] = usePayPalScriptReducer();

  // useEffect(() => {
  //   dispatch({
  //     type: "resetOptions",
  //     value: {
  //       ...options,
  //       intent: "subscription",
  //     },
  //   });
  // }, [type]);

  type ItemProps = {
    id: string;
    label: string;
    icon?: any;
    price?: string;
  };

  const items: ItemProps[] = [
    {
      id: "0",
      label: "Free",
      icon: (
        <Icons
          type={"free"}
          // color={color} fill={fill}
        />
      ),
      price: "$0.00",
    },
    {
      id: "1",
      label: "Monthly",
      icon: <Icons type={"month"} />,
      price: "$9.99",
    },
    {
      id: "2",
      label: "Yearly",
      icon: <Icons type={"year"} />,
      price: "$99.99",
    },
  ];

  return (
    <Box>
      <DashboardNav />
      <Box
        paddingX={{ base: "20px", md: "5%", lg: "11.5%" }}
        paddingTop={"120px"}
      >
        <Text fontSize={28} width={{ base: "60%", md: "100%" }}>
          Subscriptions
        </Text>
        <Box>
          <Hide above="sm">
            <HStack
              paddingTop={"10px"}
              as={"button"}
              justifyContent={"space-between"}
              width={{ base: "100%", md: "40%", lg: "40%" }}
            >
              {items.map((item, id) => {
                return (
                  <Box
                    bgColor={select === item.id ? "#4253B7" : "transparent"}
                    width={{ base: "100px", md: "120px" }}
                    height={"120px"}
                    alignItems={"center"}
                    paddingX={3}
                    paddingY={5}
                    borderRadius={"30px"}
                    borderWidth={1}
                    onClick={() => {
                      setSelect(item.id);
                    }}
                  >
                    <VStack spacing={1} align="center">
                      {" "}
                      {/* Use VStack to wrap the icon and text */}
                      {/* {item.icon} */}
                      {React.cloneElement(item.icon, {
                        color: select ? "white" : "black",
                        fill: select ? "black" : "white",
                      })}
                      <Text
                        color={select === item.id ? "white" : "black"}
                        fontSize={{ base: 13, md: 16 }}
                      >
                        {item.label}
                      </Text>
                      <Text
                        color={select === item.id ? "white" : "black"}
                        fontSize={{ base: 13, md: 16 }}
                      >
                        {item.price}
                      </Text>
                    </VStack>
                  </Box>
                );
              })}
            </HStack>
          </Hide>
          <Hide above="md">
            {select === "0" ? (
              <FreeTier />
            ) : select === "1" ? (
              <MonthlyTier />
            ) : select === "2" ? (
              <YearlyTier />
            ) : null}
          </Hide>
          {/* <PayPalButtons /> */}
          <Show above="sm">
            <Box marginY={5}>
              <VStack alignItems={"flex-start"}>
                <Box
                  alignItems={"center"}
                  borderWidth={1}
                  borderRadius={15}
                  padding={7}
                  width={"80%"}
                >
                  <Box
                    // bgColor={select === item.id ? "#4253B7" : "transparent"}
                    width={{ base: "100px", md: "120px" }}
                    height={"120px"}
                    alignItems={"center"}
                    paddingX={3}
                    paddingY={5}
                    borderRadius={"30px"}
                    borderWidth={1}
                    justifyContent={"center"}
                  >
                    <Icons
                      type={"free"}
                      // color={color} fill={fill}
                    />
                    <Text>Free</Text>
                    <Text>$0.00</Text>
                  </Box>
                  <Box paddingTop={3}>
                    <Text fontSize={18}>Free tier has access to </Text>
                    <Box paddingLeft={1}>
                      <Text>-Basic Numerology</Text>
                      <Text>-Dream Symbol Library</Text>
                      <Text>-Educative Articles</Text>
                      <Text>-Community Access</Text>
                      <Text>-Introductory Webinars</Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  alignItems={"center"}
                  borderWidth={1}
                  borderRadius={15}
                  padding={7}
                  width={"80%"}
                  marginTop={4}
                >
                  <Box
                    // bgColor={select === item.id ? "#4253B7" : "transparent"}
                    width={{ base: "100px", md: "120px" }}
                    height={"120px"}
                    alignItems={"center"}
                    paddingX={3}
                    paddingY={5}
                    borderRadius={"30px"}
                    borderWidth={1}
                    justifyContent={"center"}
                  >
                    <Icons
                      type={"free"}
                      // color={color} fill={fill}
                    />
                    <Text>Monthly</Text>
                    <Text>$9.99</Text>
                  </Box>
                  <Box paddingTop={3}>
                    <Text fontSize={18}>
                      Monthly subscription has access to{" "}
                    </Text>
                    <Box paddingLeft={1}>
                      <Text>-Personalized Numerology Reports</Text>
                      <Text>-Advanced Dream Interpretations</Text>
                      <Text>-Monthly Expert Webinars</Text>
                      <Text>-Unlimited Access to Educational Content</Text>
                      <Text>-Priority Customer Support</Text>
                      <Text>-Exclusive Promotional Offers</Text>
                    </Box>
                  </Box>
                  <Box paddingTop={10} borderRadius={10}>
                    <PayPalButtons
                      createSubscription={(data, actions) => {
                        return actions.subscription.create({
                          plan_id: "P-3RX065706M3469222L5IFM4I",
                        });
                      }}
                      style={
                        {
                          // label: "subscribe",
                        }
                      }
                    />
                  </Box>
                </Box>
                <Box
                  alignItems={"center"}
                  borderWidth={1}
                  borderRadius={15}
                  padding={7}
                  width={"80%"}
                  marginTop={4}
                >
                  <Box
                    // bgColor={select === item.id ? "#4253B7" : "transparent"}
                    width={{ base: "100px", md: "120px" }}
                    height={"120px"}
                    alignItems={"center"}
                    paddingX={3}
                    paddingY={5}
                    borderRadius={"30px"}
                    borderWidth={1}
                    justifyContent={"center"}
                  >
                    <Icons
                      type={"free"}
                      // color={color} fill={fill}
                    />
                    <Text>Monthly</Text>
                    <Text>$9.99</Text>
                  </Box>
                  <Box paddingTop={3}>
                    <Text fontSize={18}>
                      Yearly subscription has access to{" "}
                    </Text>
                    <Box paddingLeft={1}>
                      <Text>-Personalized Numerology Reports</Text>
                      <Text>-Advanced Dream Interpretations</Text>
                      <Text>-Monthly Expert Webinars</Text>
                      <Text>-Unlimited Access to Educational Content</Text>
                      <Text>-Priority Customer Support</Text>
                      <Text>-Exclusive Promotional Offers</Text>
                    </Box>
                  </Box>
                  <Box paddingTop={10} borderRadius={10}>
                    <PayPalButtons
                      createSubscription={(data, actions) => {
                        return actions.subscription.create({
                          plan_id: "P-3RX065706M3469222L5IFM4I",
                        });
                      }}
                      style={
                        {
                          // label: "subscribe",
                        }
                      }
                    />
                  </Box>
                </Box>
                <Box>
                  <PayPalButton />
                </Box>
              </VStack>
            </Box>
          </Show>
        </Box>
      </Box>
    </Box>
  );
}

export default Subscriptions;
