import React, { useEffect, useState } from "react";
import {
  Box,
  //Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import DashboardNav from "../../components/DashboardNav";
import SubNav from "../../components/SubNav";
import Icons from "../../assets/Icons";
import Request from "../../api/request";
import axios from "axios";
import { loadtoken, savetoken } from "../../redux/token";
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import { colors } from "../../theme/colors";
import {
  likePost,
  makeComment,
  makePost,
} from "../../redux/actions/postActions";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

function ChatForum({
  commentLoading,
  commentError,
  commentSuccess,
  callCommentRequest,
  likesSuccess,
  likesError,
  likesLoading,
  callLikesRequest,
}: any) {
  const location = useLocation();
  const [postData, setPostData] = useState<any>({});
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [post, setPost] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isloading, setIsLoading] = useState(false);
  const baseUrl = Request.url;
  const token = loadtoken();
  const [getItems, setGetItems] = useState([]);
  const getComment = location.state?.id;
  const [clickLike, setClickLike] = useState(false);

  // console.log(text, "write something")

  const calculateTimeDifference = (createdOn: string) => {
    const createdTime = new Date(createdOn).getTime();
    const currentTime = new Date().getTime();
    const differenceInMinutes = Math.floor((currentTime - createdTime) / 60000);

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} mins ago`;
    } else if (differenceInMinutes < 1440) {
      // less than 24 hours
      const hours = Math.floor(differenceInMinutes / 60);
      return `${hours} hr${hours > 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(differenceInMinutes / 1440);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };

  useEffect(() => {
    if (location.state) {
      setPostData(location.state);
    } else {
      //caLLL GET API
    }
  }, []);
  // console.log(location.state.id, "get comments");

  useEffect(() => {
    fetchCommentData();
  }, []);

  const fetchCommentData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/community/post/${getComment}/comment`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const sortedComments = response.data.sort(
        (a: any, b: any) =>
          new Date(a.created_on).getTime() - new Date(b.created_on).getTime()
      );
      setGetItems(sortedComments);
      console.log(response?.data, "data");
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const timestamp = new Date();
    const formattedTimestamp = timestamp.toISOString();
    const res = await callCommentRequest({
      post: getComment,
      text: text,
      //timestamp: formattedTimestamp,
    });
    if (res.status === 201) {
      console.log(commentSuccess);
      // onClose();
      savetoken(res?.data?.access);
      await fetchCommentData();
      setText("");
    } else {
      console.log(commentError);
      // console.log(res);
    }
  };

  const handleLikes = async () => {
    const timestamp = new Date();
    const formattedTimestamp = timestamp.toISOString();
    const res = await callLikesRequest({
      getComment,
    });
    if (res.status === 200) {
      console.log(likesSuccess);
      setClickLike(!clickLike);
      savetoken(res?.data?.access);
      setText("");
    } else {
      console.log(likesError);
      // console.log(res);
    }
  };

  return (
    <Box>
      <DashboardNav />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "65px", md: "65px", lg: "100px" }}
      >
        <SubNav
          title={"Forum Article"}
          icon={<Icons type="share" />}
          show={true}
        />
        <Box
          paddingLeft={"20px"}
          borderBottomWidth={1}
          paddingBottom={10}
          alignItems={"flex-start"}
        >
          <Text fontWeight={"700"} paddingTop={"100px"}>
            {postData.title}
          </Text>
          <Text fontWeight={"400"} paddingTop={0} textAlign={"left"}>
            {postData.text}
          </Text>
          <HStack>
            <HStack>
              <Icons type="time" />
              <Text fontSize={12}>
                {/* {new Date(postData.created_on).toLocaleDateString()} */}
                {calculateTimeDifference(postData.created_on)}
              </Text>
            </HStack>
            <HStack>
              <Icons type="reply" />
              <Text fontSize={12}>12 Replies</Text>
            </HStack>
            <HStack>
              {/* <Icons type="reply" /> */}
              <Text
                fontSize={12}
                as={"button"}
                onClick={() => {
                  handleLikes();
                }}
              >
                {postData.likes} {clickLike === true ? "unlike" : "likes"}
              </Text>
            </HStack>
            <Text fontSize={12} color={"#dc143c"} as={'button'}>Report</Text>
          </HStack>
          {/* <Text paddingTop={"30px"}>{postData.text}</Text> */}
        </Box>
        {getItems.map((items: any, id: any) => {
          return (
            <Box
              paddingTop={8}
              borderBottomWidth={1}
              paddingBottom={5}
              // alignItems={"center"}
              width={"100%"}
              as="button"
              key={id}
            >
              <HStack
                alignItems={"flex-start"}
                display={"flex"}
                //justifyContent={"center"}
                paddingLeft={"40px"}
              >
                {items.user.get_profile_image ? (
                  <Image
                    src={items.user.get_profile_image}
                    width={"40px"}
                    height={"40px"}
                    borderRadius={"100%"}
                    borderWidth={1}
                    bgColor={"#009688"}
                  />
                ) : (
                  <Box
                    width={"40px"}
                    height={"40px"}
                    borderRadius={"100%"}
                    borderWidth={1}
                    bgColor={"#009688"}
                  />
                )}
                <Box paddingLeft={2}>
                  <Text fontWeight={"500"} fontSize={20} textAlign={"left"}>
                    {items.user.username}
                  </Text>
                  <Text textAlign={"left"}>{items.text}</Text>
                  <HStack>
                    <HStack>
                      <Icons type="time" />
                      <Text fontSize={12}>
                        {" "}
                        {items && items.created_on
                          ? calculateTimeDifference(items.created_on)
                          : "Unknown"}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={12} onClick={onOpen}>
                        Reply
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={12} onClick={onOpen}>
                        {items.likes} Likes
                      </Text>
                    </HStack>
                  </HStack>
                </Box>
              </HStack>
            </Box>
          );
        })}

        <Box
          borderTopWidth={1}
          width={"100%"}
          bgColor={"white"}
          height={"100px"}
          position={"fixed"}
          top={"480px"}
          paddingTop={"25px"}
        >
          <HStack
            width={{ base: "100%", md: "60%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Textarea
              placeholder="type your message here"
              bgColor={"#F1F1F1"}
              color={"black"}
              width={"60%"}
              height={"48px"}
              borderRadius={20}
              value={text}
              onChange={(e: any) => {
                setText(e.target.value);
                //setPost(post);
              }}
            />
            <Box as="button" paddingBottom={4} onClick={handleSubmit}>
              <Icons type="send" />
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  commentLoading: state.auth.commentLoading,
  commentSuccess: state.auth.commentSuccess,
  commentError: state.auth.commentError,
  likesLoading: state.auth.likesLoading,
  likesSuccess: state.auth.likesSuccess,
  likesError: state.auth.likesError,
});

const mapDispatch = (dispatch: any) => ({
  callCommentRequest: (data: any) => dispatch(makeComment(data)),
  callLikesRequest: (id: any) => dispatch(likePost({}, id)),
});

export default connect(mapState, mapDispatch)(ChatForum);
