import {
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_LOADING,
  NEW_PASSWORD_SUCCESS,
  PROFILE_ERROR,
  PROFILE_LOADING,
  PROFILE_SUCCESS,
  REGISTER_ERROR,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  VERIFY_ERROR,
  VERIFY_LOADING,
  VERIFY_SUCCESS,
} from "./types";

const initialState = {
  loginLoading: null,
  loginSuccess: null,
  loginError: null,
  registerLoading: null,
  registerSuccess: null,
  registerError: null,
  forgotPasswordLoading: null,
  forgotPasswordSuccess: null,
  forgotPasswordError: null,
  newPasswordLoading: null,
  newPasswordSuccess: null,
  newPasswordError: null,
  verifyAccountLoading: null,
  verifyAccountSuccess: null,
  verifyAccountError: null,
  profileSuccess: null,
  profileError: null,
  profileLoading: null,
};

export default function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case LOGIN_LOADING:
      return { ...state, loginLoading: action.payload };
    case LOGIN_SUCCESS:
      return { ...state, loginSuccess: action.payload };
    case LOGIN_ERROR:
      return { ...state, loginError: action.payload };
    case REGISTER_LOADING:
      return { ...state, registerLoading: action.payload };
    case REGISTER_SUCCESS:
      return { ...state, registerSuccess: action.payload };
    case REGISTER_ERROR:
      return { ...state, registerError: action.payload };
      case FORGOT_PASSWORD_LOADING:
      return { ...state, forgotPasswordLoading: action.payload };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordSuccess: action.payload };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, forgotPasswordError: action.payload };
      case NEW_PASSWORD_LOADING:
      return { ...state, newPasswordLoading: action.payload };
    case NEW_PASSWORD_SUCCESS:
      return { ...state, newPasswordSuccess: action.payload };
    case NEW_PASSWORD_ERROR:
      return { ...state, newPasswordError: action.payload };
    case VERIFY_LOADING:
      return { ...state, verifyAccountLoading: action.payload };
    case VERIFY_SUCCESS:
      return { ...state, verifyAccountSuccess: action.payload };
    case VERIFY_ERROR:
      return { ...state, verifyAccountError: action.payload };
    case PROFILE_ERROR:
      return { ...state, profileError: action.payload };
    case PROFILE_LOADING:
      return { ...state, profileLoading: action.payload };
    case PROFILE_SUCCESS:
      return { ...state, profileSuccess: action.payload };
    default:
      return state;
  }
}
