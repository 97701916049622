import {
  POST_LOADING,
  POST_ERROR,
  POST_SUCCESS,
  COMMENT_ERROR,
  COMMENT_LOADING,
  COMMENT_SUCCESS,
  LIKES_ERROR,
  LIKES_LOADING,
  LIKES_SUCCESS,
  UNLIKES_ERROR,
  UNLIKES_LOADING,
  UNLIKES_SUCCESS,
} from "./types";

const initialState = {
  postLoading: null,
  postSuccess: null,
  postError: null,
  commentSuccess: null,
  commentError: null,
  commentLoading: null,
  likesSuccess: null,
  likesError: null,
  likesLoading: null,
  unlikesSuccess: null,
  unlikesError: null,
  unlikesLoading: null,
};

export default function postReducer(state = initialState, action: any) {
  switch (action.type) {
    case POST_ERROR:
      return { ...state, postError: action.payload };
    case POST_LOADING:
      return { ...state, postLoading: action.payload };
    case POST_SUCCESS:
      return { ...state, postSuccess: action.payload };
    case COMMENT_ERROR:
      return { ...state, commentError: action.payload };
    case COMMENT_LOADING:
      return { ...state, commentLoading: action.payload };
    case COMMENT_SUCCESS:
      return { ...state, commentSuccess: action.payload };
    case LIKES_ERROR:
      return { ...state, likesError: action.payload };
    case LIKES_LOADING:
      return { ...state, likesLoading: action.payload };
    case LIKES_SUCCESS:
      return { ...state, likesSuccess: action.payload };
      case UNLIKES_ERROR:
        return { ...state, unlikesError: action.payload };
      case UNLIKES_LOADING:
        return { ...state, unlikesLoading: action.payload };
      case UNLIKES_SUCCESS:
        return { ...state, unlikesSuccess: action.payload };
    default:
      return state;
  }
}
