import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import PaypalButtonComponent from '../paypal';


function FreeTier(){
    return (
        <Box paddingTop={3}>
            <Text fontSize={18}>Free tier has access to </Text>
            <Box paddingLeft={1}>
                <Text>-Basic Numerology</Text>
                <Text>-Dream Symbol Library</Text>
                <Text>-Educative Articles</Text>
                <Text>-Community Access</Text>
                <Text>-Introductory Webinars</Text>
            </Box>
        </Box>
    )
}

export default FreeTier;