import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Icons from "../assets/Icons";

interface NavProps {
  title: string;
  show?: boolean;
  icon?: any;
  onClick?: () => void;
  width?: any;
  mainWidth?: any;
}

export default function SubNav({
  title,
  show,
  icon,
  onClick,
  width,
  mainWidth,
}: NavProps) {
  const navigate = useNavigate();
  return (
    <Box width={"100%"} bgColor={"white"}>
      <Box
        width={{ base: "100%", md: mainWidth ? mainWidth : "86%", lg: "100%" }}
        paddingY={"20px"}
        position={"fixed"}
        backgroundColor={"white"}
        paddingRight={{ base: "40px", md: 0 }}
      >
        <HStack
          justifyContent={"space-between"}
          width={{
            base: width ? width : "100%",
            md: width ? width : "76%",
            lg: width ? width : "76%",
          }}
        >
          <Box onClick={() => navigate(-1)}>
            <Icons type="back" />
          </Box>
          <Text textAlign="center" flex="1">
            {title}
          </Text>
          {show && icon ? (
            <Box onClick={onClick} as="button">
              {icon}
            </Box>
          ) : (
            <Box></Box>
          )}
        </HStack>
      </Box>
    </Box>
  );
}
