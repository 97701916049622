import {
  Box,
  Center,
  Text,
  Image,
  HStack,
  VStack,
  useLatestRef,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DashboardNav from "../../components/DashboardNav";
import SubNav from "../../components/SubNav";
import NumPred from "../../assets/NumberPred.svg";
import Button from "../../components/Button";
import { colors } from "../../theme/colors";
import Icons from "../../assets/Icons";
import Request from "../../api/request";
import { loadtoken, savetoken } from "../../redux/token";
import axios from "axios";
import { connect } from "react-redux";
import { generate_birtdate } from "../../redux/actions/gamesAction";
import { useLocation, useNavigate } from "react-router-dom";
import MainNav from "../../components/MainNav";

function SixDigitBirthdayLotteryGame({
  birthdateLoading,
  birthdateSuccess,
  birthdateError,
  generateGameRequest,
}: any) {
  const baseUrl = Request.url;
  const [data, setData] = useState(["00", "00", "00", "00", "00", "00"]);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState({
    birth_date: "",
    min: "",
    sec: "",
    hour: "",
  });
  const navigation = useNavigate();
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    if (!location?.state?.number) {
      return navigation("/six-digit-birthday");
    }

    setData(location?.state?.number);
  }, []);

  // const handleGame = () => {
  //   const token = loadtoken();
  //   console.log(token, "get, unique string");
  //   setLoading(true);
  //   axios
  //     .get(`${baseUrl}/predictor/predict`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         Accept: "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       // console.log(response.data, "lol");
  //       setData(response.data.number);
  //       console.log(response.data.number, "numbers");
  //       setLoading(false);
  //       //setUser(data);
  //       //setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    const getDataArr = () => {
      const token = loadtoken();
      // console.log(token, "get, unique string");
      setLoading(true);
      axios
        .get(`${baseUrl}/predictor/get-lotteries`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          // console.log(response.data, "lol");
          setGetData(response.data);
          console.log(response.data, "numbers");
          setLoading(false);
          //setUser(data);
          //setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    getDataArr();
  }, []);

  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={'Lottery Name'} />
      <Box
        paddingX={{ base: "20px", md: "11%" }}
        // paddingTop={{ base: "65px", md: "100px" }}
      >
        {/* <SubNav title={"Lottery Name"} /> */}
        <Box paddingTop={{ base: "90px", md: "90px" }}>
          <Box
            bgColor={"#DBDBDB"}
            padding={{ base: 7, md: 9 }}
            borderRadius={10}
          >
            <Center>
              <Image src={NumPred} />
            </Center>
            <VStack
              width={{ base: "100%", md: "100%", lg: "100%" }}
              paddingTop={3}
              paddingLeft={{ base: 0, md: "6px", lg: "26px" }}
            >
              <HStack
                display="flex"
                justifyContent={"space-between"}
                paddingY={2}
                alignContent={"center"}
              >
                {data.slice(0, 6).map((item: any, index: number) => (
                  <Box
                    key={index}
                    bgColor={"#4253B7"}
                    padding={{base: '9px', md: 5}}
                    borderRadius={8}
                  >
                    <Text color={"white"}>{item}</Text>
                  </Box>
                ))}
              </HStack>
            </VStack>
          </Box>
          <Box paddingTop={10}>
            <HStack>
              <Box
                width={{ base: "29.5%", md: "38.5%", lg: "43.5%" }}
                borderWidth={2}
                borderColor={"#009688"}
                opacity={0.2}
              />
              <Text color={"#009688"}>Past Generation</Text>
              <Box
                width={{ base: "29.5%", md: "38.5%", lg: "43.5%" }}
                borderWidth={2}
                borderColor={"#009688"}
                opacity={0.2}
              />
            </HStack>
            <Center>
              <VStack width={"100%"}>
                <HStack
                  justifyContent={"space-between"}
                  width={{ base: "100%", md: "70%", lg: "40%" }}
                >
                  <Text>Contest #3467</Text>
                  <Box>Nov 24, 2023</Box>
                </HStack>
                {/* <HStack
                  display="flex"
                  justifyContent={"space-between"}
                  width={{ base: "100%", md: "70%", lg: "40%" }}
                  paddingY={6}
                  alignContent={"center"}
                  borderBottomWidth={"2px"}
                  marginBottom={2}
                >
                  {getData.slice(0, 4).map((items: any, index: number) => {
                    return (
                      <Box
                        bgColor={"#DBDBDB"}
                        padding={7}
                        borderRadius={8}
                        key={index}
                      >
                        <Text color={"black"}>{items.prediction}</Text>
                      </Box>
                    );
                  })}
                </HStack> */}
                {getData &&
                  getData.length > 0 &&
                  getData?.slice(0, 5)?.map((eachObj: any, index) => {
                    return (
                      <HStack
                        key={index}
                        display="flex"
                        justifyContent={"space-between"}
                        width={{ base: "100%", md: "70%", lg: "40%" }}
                        paddingY={6}
                        alignContent={"center"}
                        borderBottomWidth={"2px"}
                        marginBottom={2}
                      >
                        {JSON.parse(eachObj?.prediction)
                          .slice(0, 6)
                          .map((eachNumber: any, index: number) => {
                            return (
                              <Box
                                bgColor={"#DBDBDB"}
                                padding={{base: '9px', md: 5}}
                                borderRadius={8}
                                key={index}
                              >
                                <Text color={"black"}>{eachNumber}</Text>
                              </Box>
                            );
                          })}
                      </HStack>
                    );
                  })}
              </VStack>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  birthdateLoading: state.auth.birthdateLoading,
  birthdateSuccess: state.auth.birthdateSuccess,
  birthdateError: state.auth.birthdateError,
});

const mapDispatch = (dispatch: any) => ({
  generateGameRequest: (data: any) => dispatch(generate_birtdate(data)),
});

export default connect(mapState, mapDispatch)(SixDigitBirthdayLotteryGame);
