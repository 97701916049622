import {
  Box,
  Text,
  // Image,
  Center,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
// import Logo from '../../assets/Logo.svg'
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import Icons from "../../assets/Icons";
import SubNav from "../../components/SubNav";
import { useNavigate } from "react-router-dom";
import { fireRegister } from "../../redux/actions/authAction";
import { connect } from "react-redux";

const Register = ({
  callRegisterRequest,
  registerError,
  registerLoading,
  registerSuccess,
}: any) => {
  // const [select, setSelect] = useState(false)
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const handleSelect = () => {
  //     setSelect(!select)
  // }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  function validateEmail(email: any) {
    // Regular expression for email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  if (validateEmail(email)) {
    console.log("Valid email address");
  } else {
    console.log("Invalid email address");
  }

  useEffect(() => {
    if (registerError) {
      alert(registerError); // Show alert if there's a register error
    }
  }, [registerError]);

  function convertToLowerCase(inputString: any) {
    return inputString.toLowerCase();
  }

  return (
    <Box
      paddingX={{ base: "20px", md: "15%" }}
      bgColor={"white"}
      height={"100vh"}
    >
      <SubNav title={"Sign up"} />
      <Box
        //width={'full'}
        //alignItems={'center'}
        paddingX={{ base: "20px", md: "20%", lg: "30%" }}
      >
        <Center paddingTop={"10px"} width={"100%"}>
          <VStack width={"100%"}>
            <Box
              width={"92px"}
              height={"92px"}
              borderRadius={"100%"}
              bgColor={"white"}
              paddingTop={"5px"}
              paddingLeft={"5px"}
              borderWidth={1}
              shadow={"md"}
              marginBottom={2}
            >
              <Box
                width={"80px"}
                height={"80px"}
                borderRadius={"100%"}
                bgColor={"#CFD9E2"}
                paddingTop={"15px"}
                paddingX={"20px"}
                borderWidth={1}
                shadow={"md"}
              >
                <Icons type="man" />
              </Box>
            </Box>
            <InputField
              onChange={(e: any) => {
                setUsername(e.target.value);
              }}
              placeholder="Username"
              value={username}
              width={"100%"}
              height={"48px"}
              color={"black"}
            />
            <InputField
              placeholder="Email"
              width={"100%"}
              height={"48px"}
              color={"black"}
              type="email"
              value={convertToLowerCase(email)}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
            <Box width={"100%"}>
              <InputField
                placeholder="Password"
                width={"100%"}
                height={"48px"}
                color={"black"}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                rightElement={<Icons type="hidePassword" width={20} />}
                rightElementClick={togglePasswordVisibility}
              />
            </Box>
            <InputField
              placeholder="Confirm Password"
              width={"100%"}
              height={"48px"}
              color={"black"}
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e: any) => {
                setConfirmPassword(e.target.value);
              }}
              rightElement={<Icons type="hidePassword" width={20} />}
              rightElementClick={togglePasswordVisibility2}
            />
            <Box width={"100%"} paddingTop={"10px"}>
              <Button
                onClick={async () => {
                  if (!validateEmail(email)) {
                    alert("Invalid email address");
                    return; // Exit function if email is invalid
                  }
                  if (password !== confirmPassword) {
                    toast({
                      title: "warning",
                      isClosable: true,
                      description: "passwords do not match",
                      duration: 2000,
                      status: "warning",
                      position: "top",
                    });
                    return;
                  }
                  const res = await callRegisterRequest({
                    username,
                    email,
                    password,
                  });
                  if (res.status === 201) {
                    console.log(registerSuccess);
                    navigation("/verify-account", { state: { email } });
                    toast({
                      title: "success",
                      isClosable: true,
                      description: res.data.message,
                      duration: 2000,
                      status: "success",
                      position: "top",
                    });
                  } else {
                    console.log(registerError);
                    toast({
                      title: "error",
                      isClosable: true,
                      description: res.error,
                      duration: 2000,
                      status: "error",
                      position: "top",
                    });
                  }
                }}
                loading={registerLoading}
                label={"CREATE ACCOUNT"}
                bgColor={colors.primary}
                width={{ base: "100%", md: "100%" }}
                borderRadius={"33px"}
                height={"48px"}
                color="white"
                fontWeight={"300"}
                paddingX={4}
                fontSize={16}
                icon={<Icons type="forward" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              />
            </Box>
          </VStack>
        </Center>
        <HStack justifyContent={"center"}>
          <Box borderWidth={1} width={"25%"} />
          <Text color={"#C4C4C4"}>OR SIGN IN WITH</Text>
          <Box borderWidth={1} width={"25%"} />
        </HStack>
        <HStack
          paddingX={{ base: "30%", md: "38%" }}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Icons type={"facebook"} />
          <Icons type={"google"} />
        </HStack>
      </Box>
    </Box>
  );
};

const mapState = (state: any) => ({
  registerLoading: state.auth.registerLoading,
  registerSuccess: state.auth.registerSuccess,
  registerError: state.auth.registerError,
});

const mapDispatch = (dispatch: any) => ({
  callRegisterRequest: (data: any) => dispatch(fireRegister(data)),
});

export default connect(mapState, mapDispatch)(Register);
