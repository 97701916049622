import * as React from "react";
import { ChakraProvider, theme, useToast } from "@chakra-ui/react";

import LandingPage from "./features/LandingPage";
import Register from "./features/auth/Register";
// import SubNav from "./components/SubNav"
// import NewPassword from "./features/auth/NewPassword"
import Login from "./features/auth/Login";
// import ForgotPassword from "./features/auth/ForgotPassword"
// import NumberInput from "./features/auth/NumberInput"
// import VerifyAccount from "./features/auth/VerifyAccount"

import Dashboard from "./features/dashboard";
import { Route, Routes } from "react-router-dom";
import NumerologyPrediction from "./features/numerology-prediction";
//import NewPassword from "./features/auth/NewPassword"
import ForgotPassword from "./features/auth/ForgotPassword";
import LotteryGame from "./features/numerology-prediction/LotteryGame";
import BirthdayScreen from "./features/basics-of-numerology/BirthdayScreen";
import PrivateRoute from "./components/PrivatRoute";
import VerifyAccount from "./features/auth/VerifyAccount";
import CommunityForum from "./features/community-forum";
import ChatForum from "./features/community-forum/chat-forum";
import DreamInterpretation from "./features/basics-of-numerology/Dream-Interpretation";
import SavedNumbers from "./features/saved-numbers";
import Notifications from "./features/notifications";
import BirthdayLotteryGame from "./features/numerology-prediction/Birthdate_lotteryGame";
import BirthdayScreens from "./features/birthday-screens";
import DreamsInterpretationScreens from "./features/dreams-interpretations";
import SixDigitLotteryGame from "./features/numerology-prediction/SixDigitLotteryGame";
import SixDigitBirthdayScreen from "./features/basics-of-numerology/SixDigitBirthdayScreen";

import SixDigitDreamInterpretation from "./features/basics-of-numerology/Six-Digit-Dream-Interpretation";
import SixDigitDreamInterpretationLotteryGame from "./features/numerology-prediction/SixDigitDreamInterpretationLotteryGame";

import Settings from "./features/settings";
import Profile from "./features/profile";
import EducationalNumerology from "./features/education-components/educational-numerology";
import BasicIntroduction from "./features/education-components/educational-numerology/basic-introduction";
import PredictiveAnalytics from "./features/education-components/educational-numerology/predictive-analytics";
import NumerologyInsight from "./features/education-components/educational-numerology/numerology-insight";
import DreamInterpretationEdu from "./features/education-components/educational-numerology/dream-interpretation-edu";
import Privacy from "./features/auth/privacy";
import NewPassword from "./features/auth/NewPassword";
import DreamGame from "./features/dreams-interpretations/dream-screen";
import SixDreamGame from "./features/dreams-interpretations/six-dream-screen";
import SixDreamInterpretation from "./features/basics-of-numerology/SixDream-interpretation";
import SixDigitBirthdateLotteryGame from "./features/numerology-prediction/Six-Digit-BirthdateLotteryGame";
import DreamDictionary from "./features/dreams-interpretations/dream-dictionary";
import EducationalOption from "./features/education-components/educational-option";
import EduOptionIntroduction from "./features/education-components/educational-option/education-introduction";
import PredictiveAnalysis from "./features/education-components/educational-option/predictive-analysis";
import EducationalNumerologyInsight from "./features/education-components/educational-option/edu-numerology-insight";
import EducationalDreamInterpretation from "./features/education-components/educational-option/edu-dream-interpretation";
import Subscriptions from "./features/subscriptions";
import ChatUI from "./features/chat";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/create" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="verify-account" element={<VerifyAccount />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="new-password" element={<NewPassword />} />
            {/* <Route path="update-profile" element={<CompleteProfile />} /> */}
            <Route
              path="number-prediction"
              element={<NumerologyPrediction />}
            />
            <Route path="generate-screen" element={<LotteryGame />} />
            <Route path="dream-screen" element={<DreamGame />} />
            <Route
              path="generate-power-screen"
              element={<SixDigitLotteryGame />}
            />
            <Route
              path="generate-birthday-screen"
              element={<BirthdayLotteryGame />}
            />
            <Route path="birthday" element={<BirthdayScreen />} />
            <Route
              path="six-digit-birthday"
              element={<SixDigitBirthdayScreen />}
            />
            <Route
              path="six-digit-power-birthday"
              element={<SixDigitBirthdateLotteryGame />}
            />
            <Route path="birthday-screens" element={<BirthdayScreens />} />
            <Route
              path="six-digit-dream-screen"
              element={<SixDreamInterpretation />}
            />
            <Route path="six-dream-interpretation" element={<SixDreamGame />} />
            <Route
              path="dream-interpretation"
              element={<DreamInterpretation />}
            />
            <Route
              path="six-digit-dream-interpretation"
              element={<SixDigitDreamInterpretation />}
            />
            <Route
              path="six-digit-dream-interpretation-lottery"
              element={<SixDigitDreamInterpretationLotteryGame />}
            />
            <Route
              path="go-to-dream-interpretation"
              element={<DreamsInterpretationScreens />}
            />
            <Route path="community-forum" element={<CommunityForum />} />
            <Route path="chat-forum" element={<ChatForum />} />
            <Route path="saved-numbers" element={<SavedNumbers />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="settings" element={<Settings />} />
            <Route path="profile" element={<Profile />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route
              path="educational-numerology"
              element={<EducationalNumerology />}
            />
            <Route path="dream-dictionary" element={<DreamDictionary />} />
            <Route path="basic-introduction" element={<BasicIntroduction />} />
            <Route
              path="predictive-analytics"
              element={<PredictiveAnalytics />}
            />
            <Route path="numerology-insight" element={<NumerologyInsight />} />
            <Route
              path="dream-interpretation-edu"
              element={<DreamInterpretationEdu />}
            />
            <Route path="educational-options" element={<EducationalOption />} />
            <Route
              path="educational-introduction"
              element={<EduOptionIntroduction />}
            />
            <Route
              path="predictive-analysis"
              element={<PredictiveAnalysis />}
            />
            <Route
              path="edu-numerology-insight"
              element={<EducationalNumerologyInsight />}
            />
            <Route
              path="edu-dream-interpretation"
              element={<EducationalDreamInterpretation />}
            />
            <Route path="/chat" element={<ChatUI />} />
          </Route>
        </Routes>
      </div>
    </ChakraProvider>
  );
};
