import {
  Box,
  Center,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SubNav from "../../components/SubNav";
import Icons from "../../assets/Icons";
import Button from "../../components/Button";
import { colors } from "../../theme/colors";
import { connect } from "react-redux";
import { verifyAccount } from "../../redux/actions/authAction";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';

function VerifyAccount({
  callVerifyAccountRequest,
  verifyAccountError,
  verifyAccountLoading,
  verifyAccountSuccess,
}: any) {
  // const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otp, setOtp] = useState("");
  const toast = useToast();
  const navigation = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state.email, "data");

  // const handleChange = (index: number, value: string) => {
  //   const newOtp = [...otp];
  //   newOtp[index] = value;
  //   setOtp(newOtp);
  // };

  return (
    <Box paddingX={{ base: "20px", md: "15%" }} bgColor={"white"}>
      <SubNav title={"Verify Account"} />
      <Box
        //width={'full'}
        bgColor={"white"}
        height={"100vh"}
        //alignItems={'center'}
        paddingX={{ base: "20px", md: "30%" }}
      >
        <Center paddingTop={"50px"}>
          <VStack width={"100%"}>
            <Box
              width={"92px"}
              height={"92px"}
              borderRadius={"100%"}
              bgColor={"white"}
              paddingTop={"5px"}
              paddingLeft={"5px"}
              borderWidth={1}
              shadow={"md"}
              marginBottom={2}
            >
              <Box
                width={"80px"}
                height={"80px"}
                borderRadius={"100%"}
                bgColor={"#CFD9E2"}
                paddingTop={"15px"}
                paddingX={"20px"}
                borderWidth={1}
                shadow={"md"}
              >
                <Icons type="padlock" />
              </Box>
            </Box>
            <Text
              paddingBottom={2}
              width={{ base: "95%", md: "75%" }}
              textAlign={"center"}
            >
              Enter your correct mobile Number to recieve a verification code
            </Text>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>{" "}</span>}
              renderInput={(props: any) => <input {...props} />}
              inputStyle={{
                borderWidth: 1,
                width: '20%',
                height: 48,
                marginRight: '5px'
              }}
            />
            <Box width={"100%"} paddingTop={10}>
              <Button
                onClick={async () => {
                  const res = await callVerifyAccountRequest({
                    otp,
                    email: state.email,
                  });
                  if (res.status === 200) {
                    console.log(verifyAccountSuccess);
                    navigation("/update-profile");
                    toast({
                      title: "success",
                      isClosable: true,
                      description: res.data.message,
                      duration: 2000,
                      status: "success",
                      position: "top",
                    });
                  } else {
                    console.log(verifyAccountError);
                    toast({
                      title: "error",
                      isClosable: true,
                      description:
                        "network problem, check your network and try again",
                      duration: 2000,
                      status: "error",
                      position: "top",
                    });
                  }
                }}
                loading={verifyAccountLoading}
                label={"CONTINUE"}
                bgColor={colors.primary}
                // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                width={{ base: "100%", md: "100%" }}
                borderRadius={"33px"}
                height={"48px"}
                color="white"
                fontWeight={"300"}
                paddingX={4}
                fontSize={16}
                icon={<Icons type="forward" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              />
            </Box>
          </VStack>
        </Center>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  verifyAccountLoading: state.auth.verifyAccountLoading,
  verifyAccountSuccess: state.auth.verifyAccountSuccess,
  verifyAccountError: state.auth.verifyAccountError,
});

const mapDispatch = (dispatch: any) => ({
  callVerifyAccountRequest: (data: any) => dispatch(verifyAccount(data)),
});

export default connect(mapState, mapDispatch)(VerifyAccount);
