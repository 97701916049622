import {
  Box,
  Text,
  Image,
  Center,
  VStack,
  HStack,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { colors } from "../../theme/colors";
import Logo from "../../assets/Logo.svg";
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import Icons from "../../assets/Icons";
import SubNav from "../../components/SubNav";
import { useNavigate } from "react-router-dom";
import { saveProfile } from "../../redux/actions/authAction";
import { connect } from "react-redux";
import DashboardNav from "../../components/DashboardNav";

function Settings({
  callProfileRequest,
  profileError,
  profileLoading,
  profileSuccess,
}: any) {
  const [gender, setGender] = useState<any>("");
  const [image, setImage] = useState(null);
  const navigation = useNavigate();
  const [first_name, setFirtName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  // const [genderSelect, setGenderSelect] = useState("")
  const toast = useToast();

  //   const toggleHamburger = () => {
  //     setSelect(!select);
  //   };

  type Props = {
    id?: string;
    label?: string;
    value?: string;
  };

  const handleChange = (selectedOption: any) => {
    setGender(selectedOption);
  };

  const genderOptions = [
    { value: "1", label: "Male" },
    { value: "0", label: "Female" },
  ];

  const handleFileUpload = (e: any) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    console.log(selectedImage, "select image");
  };

  function validateEmail(email: any) {
    // Regular expression for email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  if (validateEmail(email)) {
    // console.log("Valid email address");
  } else {
    console.log("Invalid email address");
  }

  function convertToLowerCase(inputString: any) {
    return inputString.toLowerCase();
  }

  return (
    <>
      <Box zIndex={10000} position={'relative'}>
        <DashboardNav />
      </Box>

      <Box
        paddingX={{ base: "20px", md: "5%", lg: "11.5%" }}
        paddingTop={"100px"}
      >
        <Text fontSize={28} width={{ base: "60%", md: "100%" }}>
          Settings
        </Text>
        <Box
          //width={'full'}
          //alignItems={'center'}
          paddingX={{ base: "20px", md: "20%", lg: "30%" }}
          paddingBottom={{ base: 0, md: "20px" }}
        >
          <Center paddingTop={"10px"}>
            <VStack width={"100%"}>
              <form encType="multipart/form-data">
                <Box
                  width={"92px"}
                  height={"92px"}
                  borderRadius={"100%"}
                  bgColor={"white"}
                  paddingTop={"5px"}
                  paddingLeft={"5px"}
                  borderWidth={1}
                  shadow={"md"}
                  marginBottom={2}
                >
                  <label htmlFor="imageUpload">
                    <Box
                      as="input"
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      display="none"
                      onChange={handleFileUpload}
                    />
                    <Box
                      width={"80px"}
                      height={"80px"}
                      borderRadius={"100%"}
                      bgColor={"#CFD9E2"}
                      paddingTop={"15px"}
                      paddingX={"20px"}
                      borderWidth={1}
                      shadow={"md"}
                      position="relative"
                    >
                      <Icons type="man" />
                      {image && (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Profile"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </Box>
                  </label>
                </Box>
              </form>

              <InputField
                onChange={(e: any) => {
                  setFirtName(e.target.value);
                }}
                placeholder="First Name"
                width={"100%"}
                height={"48px"}
                color={"black"}
                value={first_name}
              />
              <InputField
                onChange={(e: any) => {
                  setLastName(e.target.value);
                }}
                placeholder="Last Name"
                width={"100%"}
                height={"48px"}
                color={"black"}
                value={last_name}
              />

              <Select
                placeholder="Select Gender"
                value={gender}
                // onChange={(e) => setGender(e.target.value)}
                onChange={(selectedOption: any) =>
                  setGender(selectedOption.value)
                }
                // onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                //   setGender(parseInt(e.target.value))
                // }
                width={"100%"}
                height={"48px"}
                color={colors.darkGrey}
                borderRadius={"full"}
              >
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </VStack>
          </Center>
          <HStack justifyContent={"center"}>
            <Box borderWidth={1} width={{ base: "30%", md: "35%" }} />
            <Text color={"#C4C4C4"}>Contact Info</Text>
            <Box borderWidth={1} width={{ base: "30%", md: "35%" }} />
          </HStack>
          <Center paddingTop={"10px"}>
            <VStack width={"100%"}>
              <InputField
                onChange={(e: any) => {
                  setPhoneNumber(e.target.value);
                }}
                placeholder="Phone Number"
                width={"100%"}
                height={"48px"}
                color={"black"}
                value={phone}
              />
              <InputField
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email"
                width={"100%"}
                height={"48px"}
                color={"black"}
                value={convertToLowerCase(email)}
              />
              <Box width={"100%"} paddingTop={"10px"}>
                <Button
                  onClick={async () => {
                    const res = await callProfileRequest({
                      first_name,
                      last_name,
                      image,
                      email,
                      phone,
                      gender,
                    });
                    if (res.status === 200) {
                      console.log(profileSuccess);
                      navigation("/dashboard");
                      toast({
                        title: "success",
                        isClosable: true,
                        description: "profile updated successfully",
                        duration: 2000,
                        status: "success",
                        position: "top",
                      });
                    } else {
                      console.log(profileError);
                      toast({
                        title: "error",
                        isClosable: true,
                        description:
                          "network problem, check your network and try again",
                        duration: 2000,
                        status: "error",
                        position: "top",
                      });
                    }
                  }}
                  loading={profileLoading}
                  label={"SAVE"}
                  bgColor={colors.primary}
                  // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                  width={{ base: "100%", md: "100%" }}
                  borderRadius={"33px"}
                  height={"48px"}
                  color="white"
                  fontWeight={"300"}
                  paddingX={4}
                  fontSize={16}
                  icon={<Icons type="forward" />}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                />
              </Box>
              <Text
                  as={"button"}
                  color={"black"}
                  textDecor={"underline"}
                  onClick={() => {
                    navigation("/new-password");
                  }}
                >
                  Reset Password
                </Text>
            </VStack>
          </Center>
        </Box>
      </Box>
    </>
  );
}

const mapState = (state: any) => ({
  profileLoading: state.auth.profileLoading,
  profileSuccess: state.auth.profileSuccess,
  profileError: state.auth.profileError,
});

const mapDispatch = (dispatch: any) => ({
  callProfileRequest: (data: any) => dispatch(saveProfile(data)),
});

export default connect(mapState, mapDispatch)(Settings);
