import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import Icons from "../assets/Icons";
import { colors } from "../theme/colors";
// import { Icon } from "@/assets/icons/Icon";
// import { colors } from "@/theme";

type TQuestion = {
    question: string;
    answer: string;
};

export default function FAQ({ question, answer }: TQuestion) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <VStack
            width={{ base: '100%', md: '80%' }}
            alignItems={"flex-start"}
            fontSize={{ base: "sm", lg: "md" }}
        >
            <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                bgColor={colors.lightGrey}
                paddingX={{ base: '10px', md: '20px' }}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Text fontWeight={`${isOpen && "bold"}`}>{question}</Text>
                <Text display={{ base: "block" }}>
                    <Icons
                        type={isOpen ? "arrowUp" : "arrowDown"}
                        color={colors.darkGrey}
                        width={25}
                    />
                </Text>
            </HStack>
            <Box bgColor={'white'}>
                {isOpen &&
                    <Text width={{ base: "full", md: "100%" }}
                        fontSize={{ base: 12, md: 12, lg: 16 }}
                        paddingBottom={10}>{answer}</Text>}
            </Box>
        </VStack>
    );
}
