import ls from 'localstorage-slim';

ls.config.encrypt = false;
ls.config.ttl=604800

const loadtoken = () => {
    try {
        const serializedState = ls.get('access-token')
        if(serializedState === null) {
            return undefined
        }
        return serializedState
    }
    catch(err: any) {
        return undefined
    }
}

const savetoken = (accesstoken:any) => {
    try {
        ls.set('access-token', accesstoken, { ttl: 604800})
    }
    catch(err){

    }
}

const deleteToken = () => {
    try {
        ls.remove('access-token');
    } catch (err) {
        console.error('Failed to delete token', err);
    }
};

export {loadtoken, savetoken, deleteToken}