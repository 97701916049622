import { Box, HStack, Hide, Show } from "@chakra-ui/react";
import React from "react";
import NumPrediction from "../../assets/Numerology.svg";
import NumPred from "../../assets/NumberPred.svg";
import Girl from "../../assets/Girl.svg";
import Card from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useNavigate } from "react-router-dom";

export default function LotteryInterface() {
  const navigation = useNavigate();
  type CardItemProps = {
    id: string;
    title: string;
    label: string;
    image: string;
    path?: string;
  };
  const cardItems: CardItemProps[] = [
    {
      id: "0",
      title: "Numerology Prediction",
      label:
        "Get lotto numbers that are unique to you based on your numerology",
      image: NumPrediction,
      path: "/birthday-screens",
    },
    {
      id: "1",
      title: "Number Prediction",
      label:
        "Receive personalized lottery numbers through advanced predictions. ",
      image: NumPred,
      path: "/number-prediction",
    },
    {
      id: "1",
      title: "Dream Interpretation",
      label: "Translate your dreams into potential lottery numbers. ",
      image: Girl,
      path: "/go-to-dream-interpretation",
    },
  ];

  return (
    <Box>
      <Hide breakpoint="(max-width: 375px)">
        <HStack justifyContent={"space-between"} paddingTop={"25px"}>
          {cardItems.map((items, id) => {
            return (
              <>
                <Card
                  id={""}
                  title={items.title}
                  label={items.label}
                  image={items.image}
                  icon={""}
                  onClick={() => {
                    navigation(`${items.path}`);
                  }}
                />
              </>
            );
          })}
        </HStack>
      </Hide>
      <Show breakpoint="(max-width: 400px)">
        <Box
          display={{ base: "flex", md: "hidden", lg: "hidden" }}
          paddingTop={"25px"}
        >
          <Swiper
            width={275}
            spaceBetween={20}
            scrollbar={{ draggable: true }}
            slidesPerView={1}
          >
            {cardItems.map((items: any, id: any) => {
              return (
                <>
                  <SwiperSlide key={items.id}>
                    <Card
                      id={items.id}
                      title={items.title}
                      label={items.label}
                      image={items.image}
                      icon={""}
                      onClick={() => {
                        navigation(`${items.path}`);
                      }}
                    />
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </Box>
      </Show>
    </Box>
  );
}
