import React, { useEffect, useState } from "react";
import {
  Box,
  //Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import DashboardNav from "../../components/DashboardNav";
import SubNav from "../../components/SubNav";
import Icons from "../../assets/Icons";
import Request from "../../api/request";
import axios from "axios";
import { loadtoken, savetoken } from "../../redux/token";
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import { colors } from "../../theme/colors";
import { makePost } from "../../redux/actions/postActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function CommunityForum({
  postLoading,
  postSuccess,
  postError,
  callPostRequest,
}: any) {
  const baseUrl = Request.url;
  const [getItems, setGetItems] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const token = loadtoken();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState("");
  console.log("get items");

  const calculateTimeDifference = (createdOn: string) => {
    const createdTime = new Date(createdOn).getTime();
    const currentTime = new Date().getTime();
    const differenceInMinutes = Math.floor((currentTime - createdTime) / 60000);

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} mins ago`;
    } else if (differenceInMinutes < 1440) {
      // less than 24 hours
      const hours = Math.floor(differenceInMinutes / 60);
      return `${hours} hr${hours > 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(differenceInMinutes / 1440);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };

  const fetchPostData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/community/get-posts`, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setGetItems(response.data);
      console.log("data");
    } catch (error) {
      // console.error("Error fetching product data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPostData();
  }, []);

  // const handleSubmit = async () => {
  //   const timestamp = new Date();
  //   const formattedTimestamp = timestamp.toISOString();
  //   const res = await callLoginRequest({
  //     title,
  //     text,
  //     timestamp: formattedTimestamp,
  //   });
  //   setLoading(true)
  //   if (res === 201) {
  //     console.log(postSuccess);
  //     setLoading(false)
  //     onClose();
  //     savetoken(res?.data?.access);
  //     fetchPostData()
  //     navigate('/')
  //     console.log(res?.data?.access, "post ")
  //   } else {
  //     console.log(postError);
  //     setLoading(false)
  //     // console.log(res);
  //   }
  // };

  return (
    <Box>
      <DashboardNav />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "65px", md: "65px", lg: "100px" }}
      >
        <SubNav
          title={"Forum Discussion"}
          icon={<Icons type="plus" />}
          show={true}
          onClick={onOpen}
          //width={'100%'}
          // mainWidth={'100%'}
        />
        <Box>
          <HStack paddingTop={10}>
            <Box
              borderWidth={1}
              width={{ base: "29%", md: "34%", lg: "40%" }}
              borderColor={"#009688"}
            />
            <Text
              paddingX={{ base: 0, md: 3, lg: 3 }}
              color={"#009688"}
              fontSize={{ base: 14, md: 18 }}
            >
              Highlighted Topics
            </Text>
            <Box
              borderWidth={1}
              width={{ base: "29%", md: "34%", lg: "40%" }}
              borderColor={"#009688"}
            />
          </HStack>
          <Box
            paddingTop={8}
            borderBottomWidth={1}
            paddingBottom={5}
            alignItems={"center"}
            width={"100%"}
            as="button"
          >
            <HStack
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                width={"40px"}
                height={"40px"}
                borderRadius={"100%"}
                borderWidth={1}
                bgColor={"#009688"}
              />
              <Box paddingLeft={2}>
                <Text fontWeight={"500"} fontSize={20} textAlign={"left"}>
                  Topic title item
                </Text>
                <HStack>
                  <HStack>
                    <Icons type="time" />
                    <Text fontSize={12}>Today, 04:32am</Text>
                  </HStack>
                  <HStack>
                    <Icons type="reply" />
                    <Text fontSize={12}>12 Replies</Text>
                  </HStack>
                </HStack>
              </Box>
            </HStack>
          </Box>
        </Box>

        <Box>
          <HStack paddingTop={10}>
            <Box
              borderWidth={1}
              width={{ base: "34%", md: "38%", lg: "40%" }}
              borderColor={"#009688"}
            />
            <Text paddingX={{ base: "20px", md: "75px" }} color={"#009688"}>
              Topics
            </Text>
            <Box
              borderWidth={1}
              width={{ base: "34%", md: "38%" }}
              borderColor={"#009688"}
            />
          </HStack>
          {getItems.map((items: any, id: number) => {
            return (
              <Box
                paddingTop={8}
                borderBottomWidth={1}
                paddingBottom={5}
                alignItems={"flex-start"}
                width={"100%"}
                as="button"
                key={id}
                onClick={() => {
                  navigate("/chat-forum", { state: items });
                }}
              >
                <HStack
                  alignItems={"flex-start"}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  width={"100%"}
                >
                  {items.image ? (
                    <Image
                      src={
                        typeof items.image === "string"
                          ? items.image
                          : "defaultImagePath"
                      }
                    />
                  ) : (
                    <Box
                      width={"40px"}
                      height={"40px"}
                      borderRadius={"100%"}
                      borderWidth={1}
                      bgColor={"#009688"}
                    />
                  )}
                  <Box paddingLeft={2}>
                    <Text
                      fontWeight={"500"}
                      fontSize={20}
                      textAlign={"left"}
                      width={"100%"}
                    >
                      {items.title}
                    </Text>
                    <HStack>
                      <HStack>
                        <Icons type="time" />
                        <Text fontSize={12}>
                          {items && items.created_on
                            ? calculateTimeDifference(items.created_on)
                            : "Unknown"}
                        </Text>
                      </HStack>
                      <HStack>
                        <Icons type="reply" />
                        <Text fontSize={12}>12 Replies</Text>
                      </HStack>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          width={"100%"}
          height={{ base: "60vh", md: "60vh" }}
          marginTop={{ base: "33vh", md: "auto" }}
          paddingX={"20px"}
        >
          <ModalHeader textAlign={"center"} paddingTop={5}>
            New Topic
          </ModalHeader>
          <ModalCloseButton />
          <Box>
            <InputField
              placeholder="Topic"
              height={"48px"}
              color={"black"}
              value={title}
              onChange={(e: any) => {
                setTitle(e.target.value);
              }}
            />
            <Textarea
              placeholder="Descriptions"
              //maxHeight={"120px"}

              color={"black"}
              borderRadius={20}
              value={text}
              onChange={(e: any) => {
                setText(e.target.value);
              }}
            />
          </Box>

          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            marginTop={{ base: "110px", md: "40px" }}
          >
            <Button
              onClick={onClose}
              label={"cancel"}
              width={"40%"}
              height={"48px"}
              // bgColor="#009688"
              borderWidth={1}
              borderColor={colors.lightGrey}
              borderRadius={"40px"}
              color={colors.darkGrey}
            />
            <Button
              label="Publish"
              onClick={async () => {
                // console.log("log")
                const timestamp = new Date();
                const formattedTimestamp = timestamp.toISOString();
                const res = await callPostRequest({
                  title,
                  text,
                  timestamp: formattedTimestamp,
                });
                setLoading(true);

                if (res.status === 201) {
                  setLoading(true);
                  onClose();
                  window.location.reload();
                  console.log(res?.data, "response data");
                  navigate("/community-forum");
                  fetchPostData();
                  // console.log(res?.data?.access, "post ")
                } else {
                  console.log(postError);
                  setLoading(false);
                  // console.log(res);
                }
              }}
              loading={postLoading}
              width={"40%"}
              height={"48px"}
              bgColor="#009688"
              borderRadius={"40px"}
              color="white"
            />
          </HStack>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const mapState = (state: any) => ({
  postLoading: state.auth.postLoading,
  postSuccess: state.auth.postSuccess,
  postError: state.auth.postError,
});

const mapDispatch = (dispatch: any) => ({
  callPostRequest: (data: any) => dispatch(makePost(data)),
});

export default connect(mapState, mapDispatch)(CommunityForum);
