import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import MainNav from "../../../components/MainNav";

export default function EducationalOption() {
  const navigate = useNavigate();
  type Props = {
    id?: string;
    title?: string;
    path?: string;
  };
  const items: Props[] = [
    {
      id: "0",
      title: "Introduction",
      path: "/educational-introduction",
    },
    {
      id: "1",
      title: "Predictive Analytics for Lottery Number",
      path: "/predictive-analysis",
    },
    {
      id: "1",
      title: "Numerology-Based Insight",
      path: "/edu-numerology-insight",
    },
    {
      id: "0",
      title: "Dream Interpretation and Numerology",
      path: "/edu-dream-interpretation",
    },
  ];

  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={""} />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "70px", md: "70px" }}
      >
        <Box paddingTop={5}>
          {items.map((v, id) => {
            return (
              <VStack
                borderWidth={1}
                width={{ base: "100%", md: "50%", lg: "50%" }}
                padding={4}
                marginTop={3}
                alignItems={'flex-start'}
                as="button"
                onClick={() => {
                  navigate(`${v.path}`);
                }}
              >
                <Text textAlign={'left'}>{v.title}</Text>
              </VStack>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
