import { Box, HStack, Hide, Show } from "@chakra-ui/react";
import React from "react";
// import NumPrediction from "../../assets/Numerology.svg";
import NumPred from "../../assets/NumberPred.svg";
import Girl from "../../assets/Girl.svg";
import Card from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useNavigate } from "react-router-dom";

export default function CommunityInterface() {
  const navigate = useNavigate();
  type CardItemProps = {
    id: string;
    title: string;
    label: string;
    image: string;
    path?: string;
  };
  const cardItems: CardItemProps[] = [
    {
      id: "0",
      title: "Forum Discussion",
      label: "Join the conversation and share insights in our forum. ",
      image: NumPred,
      path: "/community-forum",
    },
    {
      id: "1",
      title: "Community Option",
      label: "Connect and share with others in our community. ",
      image: Girl,
      path: "undefined",
    },
  ];

  return (
    <Box width={{ md: "80%", lg: "100%" }}>
      <Hide breakpoint="(max-width: 375px)">
        <HStack
          //justifyContent={'space-between'}
          paddingTop={"25px"}
          width={{ md: "90%", lg: "100%" }}
          gap="50px"
        >
          {cardItems.map((items, id) => {
            return (
              <>
                <Card
                  id={""}
                  title={items.title}
                  label={items.label}
                  image={items.image}
                  icon={""}
                  onClick={() => {
                    navigate(`${items.path}`);
                  }}
                />
              </>
            );
          })}
        </HStack>
      </Hide>
      <Show breakpoint="(max-width: 400px)">
        <Box
          display={{ base: "flex", md: "hidden", lg: "hidden" }}
          paddingTop={"25px"}
        >
          <Swiper
            width={275}
            spaceBetween={20}
            scrollbar={{ draggable: true }}
            slidesPerView={1}
          >
            {cardItems.map((items: any, id: any) => {
              return (
                <>
                  <SwiperSlide key={items.id}>
                    <Card
                      id={items.id}
                      title={items.title}
                      label={items.label}
                      image={items.image}
                      icon={""}
                      onClick={() => {
                        navigate(`${items.path}`);
                      }}
                    />
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </Box>
      </Show>
    </Box>
  );
}
