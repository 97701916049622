import { Box, Text, Center, VStack, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import Icons from "../../assets/Icons";
import { InputField } from "../../components/InputField";
import SubNav from "../../components/SubNav";
import Button from "../../components/Button";
import { colors } from "../../theme/colors";
import { connect } from "react-redux";
import { forgotPassword } from "../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";
// import { savetoken } from "../../redux/token";

function ForgotPassword({
  forgotPasswordLoading,
  forgotPasswordSuccess,
  forgotPasswordError,
  callForgotPasswordRequest,
}: any) {
  const toast = useToast();
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  return (
    <Box paddingX={{ base: "20px", md: "15%" }} bgColor={"white"}>
      <SubNav title={"Forgot Password"} />
      <Box
        //width={'full'}
        bgColor={"white"}
        height={"100vh"}
        //alignItems={'center'}
        paddingX={{ base: "20px", md: "30%" }}
      >
        <Center paddingTop={"100px"}>
          <VStack width={"100%"}>
            <Box
              width={"92px"}
              height={"92px"}
              borderRadius={"100%"}
              bgColor={"white"}
              paddingTop={"5px"}
              paddingLeft={"5px"}
              borderWidth={1}
              shadow={"md"}
              marginBottom={2}
            >
              <Box
                width={"80px"}
                height={"80px"}
                borderRadius={"100%"}
                bgColor={"#CFD9E2"}
                paddingTop={"15px"}
                paddingX={"20px"}
                borderWidth={1}
                shadow={"md"}
              >
                <Icons type="padlock" />
              </Box>
            </Box>
            <Text
              paddingBottom={2}
              width={{ base: "90%", md: "75%" }}
              textAlign={"center"}
            >
              Enter your email and we will send you instructions how to reset
              your password
            </Text>

            <InputField
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              placeholder="email@email.com"
              width={"100%"}
              height={"48px"}
              color={"black"}
              value={email}
            />
            <Box width={"100%"}>
              <Button
                onClick={async () => {
                  const res = await callForgotPasswordRequest({ email });
                  if (res.status === 200) {
                    console.log(forgotPasswordSuccess);
                    navigation("/login");
                    toast({
                      title: "success",
                      isClosable: true,
                      description: "check email and enter password again",
                      duration: 2000,
                      status: "success",
                      position: "top",
                    });
                    // localStorage.setItem("access-token", res?.data?.access)
                    //savetoken(res?.data?.access);
                  } else {
                    console.log(forgotPasswordError);
                    // console.log(res);
                    toast({
                      title: "error",
                      isClosable: true,
                      description: "invalid credentials or network problem",
                      duration: 2000,
                      status: "error",
                      position: "top",
                    });
                  }
                }}
                loading={forgotPasswordLoading}
                label={"RESET PASSWORD"}
                bgColor={colors.primary}
                // paddingX={"10px"} height={{ base: '40px', md: '50px' }}
                width={{ base: "100%", md: "100%" }}
                borderRadius={"33px"}
                height={"48px"}
                color="white"
                fontWeight={"300"}
                paddingX={4}
                fontSize={16}
                icon={<Icons type="forward" />}
                flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              />
            </Box>
          </VStack>
        </Center>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  forgotPasswordLoading: state.auth.forgotPasswordLoading,
  forgotPasswordSuccess: state.auth.forgotPasswordSuccess,
  forgotPasswordError: state.auth.forgotPasswordError,
});

const mapDispatch = (dispatch: any) => ({
  callForgotPasswordRequest: (data: any) => dispatch(forgotPassword(data)),
});

export default connect(mapState, mapDispatch)(ForgotPassword);
