import { Box, Center, Image } from "@chakra-ui/react";
import React, { useState } from "react";

import Girl from "../../assets/Numerology.svg";
import { InputField } from "../../components/InputField";
import Icons from "../../assets/Icons";
import { colors } from "../../theme/colors";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { generate_birtdate } from "../../redux/actions/gamesAction";
import MainNav from "../../components/MainNav";

function BirthdayScreen({
  birthdateLoading,
  birthdateSuccess,
  birthdateError,
  generateGameRequest,
}: any) {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const [text, setText] = useState({
    birth_date: "",
    min: "",
    sec: "",
    hour: "",
  });

  return (
    <Box bgColor={"white"} height={"100vh"}>
      {/* <DashboardNav /> */}
      <MainNav title={`${title}`} />
      <Box
        paddingX={{ base: "20px", md: "11%" }}
        // paddingTop={{ base: "65px", md: "100px" }}
      >
        {/* <SubNav title={"Numerology Prediction"} /> */}
        {/* <SubNav title={`${title}`} /> */}
        <Box paddingTop={{ base: "90px", md: "90px" }}>
          <Box
            bgColor={"#DBDBDB"}
            padding={{ base: 7, md: 9 }}
            borderRadius={10}
          >
            <Center>
              <Image src={Girl} />
            </Center>
          </Box>
          <Center>
            <Box
              paddingTop={5}
              width={{ base: "100%", md: "40%" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <InputField
                onChange={(e: any) => {
                  setText({ ...text, birth_date: e.target.value });
                }}
                placeholder="Birthday date"
                value={text.birth_date}
                width={"100%"}
                height={"48px"}
                color={"black"}
              />
              <InputField
                onChange={(e: any) => {
                  setText({ ...text, hour: e.target.value });
                }}
                placeholder="Birthday hour:"
                value={text.hour}
                width={"100%"}
                height={"48px"}
                color={"black"}
              />
              <InputField
                onChange={(e: any) => {
                  setText({ ...text, min: e.target.value });
                }}
                placeholder="Birthday minutes:"
                value={text.min}
                width={"100%"}
                height={"48px"}
                color={"black"}
              />
              <InputField
                onChange={(e: any) => {
                  setText({ ...text, sec: e.target.value });
                }}
                placeholder="Birthday seconds:"
                value={text.sec}
                width={"100%"}
                height={"48px"}
                color={"black"}
              />
              <Box paddingY={5}>
                <Button
                  onClick={async () => {
                    console.log(text);
                    const res = await generateGameRequest({
                      ...text,
                    });
                    console.log(res);
                    if (res.status === 200) {
                      console.log(birthdateSuccess);
                      navigation("/generate-birthday-screen", {
                        state: { number: res?.data?.number },
                      });
                      // localStorage.setItem("access-token", res?.data?.access)
                    } else {
                      console.log(birthdateError);
                      // console.log(res);
                    }
                  }}
                  loading={birthdateLoading}
                  label={"GENERATE NUMBERS"}
                  bgColor={colors.primary}
                  width={{ base: "100%", md: "100%" }}
                  borderRadius={"33px"}
                  height={"48px"}
                  color="white"
                  fontWeight={"300"}
                  paddingX={4}
                  fontSize={16}
                  icon={<Icons type="forward" />}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                />
              </Box>
            </Box>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}

const mapState = (state: any) => ({
  birthdateLoading: state.auth.birthdateLoading,
  birthdateSuccess: state.auth.birthdateSuccess,
  birthdateError: state.auth.birthdateError,
});

const mapDispatch = (dispatch: any) => ({
  generateGameRequest: (data: any) => dispatch(generate_birtdate(data)),
});

export default connect(mapState, mapDispatch)(BirthdayScreen);
