import { Box, Text, VStack, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import MainNav from "../../../components/MainNav";
import Course1 from "../../../assets/course-image.png";

export default function EducationalNumerology() {
  const navigate = useNavigate();
  type Props = {
    id?: string;
    title?: string;
    path?: string;
  };
  const items: Props[] = [
    {
      id: "0",
      title: "Introduction to Basics of Numerology",
      path: "/basic-introduction",
    },
    {
      id: "1",
      title: "Predictive Analytics for Lottery Number",
      path: "/predictive-analytics",
    },
    {
      id: "1",
      title: "Numerology-Based Insight",
      path: "/numerology-insight",
    },
    {
      id: "0",
      title: "Dream Interpretation and Numerology",
      path: "/dream-interpretation-edu",
    },
  ];

  type CoursesProps = {
    id?: string;
    title?: string;
    path?: string;
    image?: any;
  };

  const courses: CoursesProps[] = [
    {
      id: "0",
      title: "Intro to Basic Numerology",
      image: Course1,
      path: "/basic-introduction",
    },
    {
      id: "1",
      title: "Intro to Basic Numerology",
      image: Course1,
      path: "/basic-introduction",
    },
    {
      id: "2",
      title: "Intro to Basic Numerology",
      image: Course1,
      path: "/basic-introduction",
    },
    {
      id: "3",
      title: "Intro to Basic Numerology",
      image: Course1,
      path: "/basic-introduction",
    },
  ];

  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={""} />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "70px", md: "70px" }}
      >
        {/* <Box paddingTop={5}>
          {items.map((v, id) => {
            return (
              <VStack
                borderWidth={1}
                width={{ base: "100%", md: "50%", lg: "50%" }}
                padding={4}
                marginTop={3}
                as="button"
                onClick={() => {
                  navigate(`${v.path}`);
                }}
              >
                <Text>{v.title}</Text>
              </VStack>
            );
          })}
        </Box> */}

        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
          {courses.map((item, id) => (
            <Box
              key={id}
              borderWidth={1}
              borderRadius="md"
              overflow="hidden"
              onClick={() => {
                navigate(`${item.path}`);
              }}
            >
              <Image src={item.image} alt={item.title} />
              <Text p={2}>{item.title}</Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
