import {
  Box,
  Text,
  Image,
  Center,
  VStack,
  HStack,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import Logo from "../../assets/Logo.svg";
import { InputField } from "../../components/InputField";
import Button from "../../components/Button";
import Icons from "../../assets/Icons";
import SubNav from "../../components/SubNav";
import { useNavigate } from "react-router-dom";
import { saveProfile } from "../../redux/actions/authAction";
import { connect } from "react-redux";
import Request from "../../api/request";
import axios from "axios";
import { loadtoken } from "../../redux/token";
import DashboardNav from "../../components/DashboardNav";

function Profile({
  callProfileRequest,
  profileError,
  profileLoading,
  profileSuccess,
}: any) {
  const [gender, setGender] = useState<any>("");
  const [first_name, setFirtName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [openHamburger, setOpenHamburger] = useState(false);
  const [image, setImage] = useState<any>("");
  const [select, setSelect] = useState("0");
  const [username, setUsername] = useState("");
  const navigation = useNavigate();
  const baseUrl = Request.url;
  // const [genderSelect, setGenderSelect] = useState("")
  const toast = useToast();

  //   const toggleHamburger = () => {
  //     setSelect(!select);
  //   };

  type Props = {
    id?: string;
    label?: string;
    value?: string;
  };

  const handleChange = (selectedOption: any) => {
    setGender(selectedOption);
  };

  const genderOptions = [
    { value: "1", label: "Male" },
    { value: "0", label: "Female" },
  ];

  const handleFileUpload = (e: any) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    console.log(selectedImage, "select image");
  };

  function validateEmail(email: any) {
    // Regular expression for email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  if (validateEmail(email)) {
    // console.log("Valid email address");
  } else {
    console.log("Invalid email address");
  }

  function convertToLowerCase(inputString: any) {
    return inputString.toLowerCase();
  }

  useEffect(() => {
    const getUserData = async () => {
      const token = loadtoken();
      console.log(token, "get, unique string");
      axios
        .get(`${baseUrl}/accounts/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response.data, "lol");
          const data = response.data;
          const {
            get_profile_image,
            first_name,
            last_name,
            email,
            phone,
            username,
          } = data;
          //setImage(get_prfile_image);
          setFirtName(first_name);
          setUsername(username);
          setLastName(last_name);
          setPhoneNumber(phone);
          setEmail(email);
          setImage(get_profile_image);
          console.log(data?.first_name, "image");
          //setUser(data);
          //setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUserData();
  }, []);

  return (
    <>
      <DashboardNav />

      {/* <Box
        paddingX={{ base: "20px", md: "15%" }}
        bgColor={"white"}
        height={"100vh"}
      > */}
      <Box
        paddingX={{ base: "20px", md: "5%", lg: "11.5%" }}
        paddingTop={"100px"}
      >
        {" "}
        <Text fontSize={28} width={{ base: "60%", md: "100%" }}>
          Profile
        </Text>
        {/* <SubNav title={"Profile"} /> */}
        <Box
          //width={'full'}
          //alignItems={'center'}
          paddingX={{ base: "20px", md: "20%", lg: "30%" }}
          paddingBottom={{ base: 0, md: "20px" }}
        >
          <Center paddingTop={"10px"}>
            <VStack width={"100%"}>
              <Image
                src={image}
                style={{
                  //position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              <Box
                width={"100%"}
                borderWidth={1}
                height={10}
                borderRadius={20}
                alignItems={"center"}
                paddingLeft={"20px"}
                paddingY={"7px"}
                marginTop={"20px"}
              >
                <Text fontSize={{ base: 14, md: 16, lg: 16 }}>{username}</Text>
              </Box>
              <Box
                width={"100%"}
                borderWidth={1}
                height={10}
                borderRadius={20}
                alignItems={"center"}
                paddingLeft={"20px"}
                paddingY={"7px"}
              >
                <Text fontSize={{ base: 14, md: 16, lg: 16 }}>
                  {first_name}
                </Text>
              </Box>
              <Box
                width={"100%"}
                borderWidth={1}
                height={10}
                borderRadius={20}
                alignItems={"center"}
                paddingLeft={"20px"}
                paddingY={"7px"}
              >
                <Text fontSize={{ base: 14, md: 16, lg: 16 }}>{last_name}</Text>
              </Box>
            </VStack>
          </Center>
          <HStack justifyContent={"center"} paddingY={"10px"}>
            <Box borderWidth={1} width={{ base: "20%", md: "35%" }} />
            <Text color={"#C4C4C4"}>Contact Info</Text>
            <Box borderWidth={1} width={{ base: "20%", md: "35%" }} />
          </HStack>
          <Center>
            <VStack width={"100%"}>
              <Box
                width={"100%"}
                borderWidth={1}
                height={10}
                borderRadius={20}
                alignItems={"center"}
                paddingLeft={"20px"}
                paddingY={"7px"}
              >
                <Text fontSize={{ base: 14, md: 16, lg: 16 }}>{email}</Text>
              </Box>
              <Box
                width={"100%"}
                borderWidth={1}
                height={10}
                borderRadius={20}
                alignItems={"center"}
                paddingLeft={"20px"}
                paddingY={"7px"}
              >
                <Text fontSize={{ base: 14, md: 16, lg: 16 }}>{phone}</Text>
              </Box>
            </VStack>
          </Center>
        </Box>
      </Box>
    </>
  );
}

const mapState = (state: any) => ({
  profileLoading: state.auth.profileLoading,
  profileSuccess: state.auth.profileSuccess,
  profileError: state.auth.profileError,
});

const mapDispatch = (dispatch: any) => ({
  callProfileRequest: (data: any) => dispatch(saveProfile(data)),
});

export default connect(mapState, mapDispatch)(Profile);
