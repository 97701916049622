import {
  BIRTHDATE_ERROR,
  BIRTHDATE_LOADING,
  BIRTHDATE_SUCCESS,
  DREAM_INTERPRETATION_ERROR,
  DREAM_INTERPRETATION_LOADING,
  DREAM_INTERPRETATION_SUCCESS,
} from "./types";

const initialState = {
  birthdayLoading: null,
  birthdaySuccess: null,
  birthdayError: null,
  dreamInterpretationLoading: null,
  dreamInterpretationSuccess: null,
  dreamInterpretationError: null,
};

export default function gameReducers(state = initialState, action: any) {
  switch (action.type) {
    case BIRTHDATE_ERROR:
      return { ...state, birthdayError: action.payload };
    case BIRTHDATE_LOADING:
      return { ...state, birthdateLoading: action.payload };
    case BIRTHDATE_SUCCESS:
      return { ...state, birthdateSuccess: action.payload };
    case DREAM_INTERPRETATION_SUCCESS:
      return { ...state, dreamInterpretationSuccess: action.payload };
    case DREAM_INTERPRETATION_ERROR:
      return { ...state, dreamInterpretationError: action.payload };
    case DREAM_INTERPRETATION_LOADING:
      return { ...state, dreamInterpretationLoading: action.payload };
    default:
      return state;
  }
}
