import {
  BIRTHDATE_ERROR,
  BIRTHDATE_LOADING,
  BIRTHDATE_SUCCESS,
  DREAM_INTERPRETATION_ERROR,
  DREAM_INTERPRETATION_LOADING,
  DREAM_INTERPRETATION_SUCCESS,
} from "../reducers/types";
import axios from "axios";
import Request from "../../api/request";
import { loadtoken } from "../token";

const baseUrl = Request.url;

export const generate_birtdate = (data: any) => async (dispatch: any) => {
  const token = loadtoken();
  //console.log(token);
  dispatch({ type: BIRTHDATE_LOADING, payload: true });
  dispatch({ type: BIRTHDATE_ERROR, payload: null });

  try {
    const resp = await axios.post(`${baseUrl}/predictor/numerology`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    if (resp.status === 200) {
      dispatch({ type: BIRTHDATE_SUCCESS, payload: resp.data });
      dispatch({ type: BIRTHDATE_LOADING, payload: false });
    }
    return resp;
  } catch (error: any) {
    console.log(error.response.data);
    dispatch({ type: BIRTHDATE_LOADING, payload: false });
    dispatch({ type: BIRTHDATE_ERROR, payload: error.data });
  }
};

export const generate_dream_interpretation =
  (data: any) => async (dispatch: any) => {
    const token = loadtoken();
    //console.log(token);
    dispatch({ type: DREAM_INTERPRETATION_LOADING, payload: true });
    dispatch({ type: DREAM_INTERPRETATION_ERROR, payload: null });

    try {
      const resp = await axios.post(`${baseUrl}/predictor/dream/`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      if (resp.status === 200) {
        dispatch({ type: DREAM_INTERPRETATION_SUCCESS, payload: resp.data });
        dispatch({ type: DREAM_INTERPRETATION_LOADING, payload: false });
      }
      return resp;
    } catch (error: any) {
      console.log(error.response.data);
      dispatch({ type: DREAM_INTERPRETATION_LOADING, payload: false });
      dispatch({ type: DREAM_INTERPRETATION_ERROR, payload: error.data });
    }
  };
