import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  VStack,
  // Image, Center
} from "@chakra-ui/react";
import React, { useState } from "react";
import DashboardNav from "../../components/DashboardNav";
// import Icons from "../../assets/Icons";
// import Girl from "../../assets/Girl.svg";
import LotteryInterface from "./LotteryInterface";
import CommunityInterface from "./CommunityInterface";
import EducationalInterface from "./EducationalInterface";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme/colors";
import { IoMdChatbubbles } from "react-icons/io";

interface Message {
  id: number;
  text: string;
  sender: "user" | "bot";
}

export default function Dashboard() {
  const [select, setSelect] = useState("0");
  const navigate = useNavigate();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  type ItemProps = {
    id: string;
    label: string;
  };

  const items: ItemProps[] = [
    {
      id: "0",
      label: "Lottery",
    },
    {
      id: "1",
      label: "Community",
    },
    {
      id: "2",
      label: "Educational",
    },
  ];

  const handleSendMessage = () => {
    if (inputValue.trim() === "") return;

    const newMessage: Message = {
      id: messages.length + 1,
      text: inputValue,
      sender: "user",
    };
    setMessages([...messages, newMessage]);

    setTimeout(() => {
      const botReply: Message = {
        id: newMessage.id + 1,
        text: `You said: ${inputValue}`,
        sender: "bot",
      };
      setMessages((prevMessages) => [...prevMessages, botReply]);
    }, 1000);

    setInputValue("");
  };

  return (
    <Box>
      <DashboardNav />
      <Box
        paddingX={{ base: "20px", md: "5%", lg: "11.5%" }}
        paddingTop={"120px"}
      >
        <Text fontSize={28} width={{ base: "60%", md: "100%" }}>
          Unlock Potential
        </Text>
        <HStack paddingTop={"10px"} as={"button"}>
          {items.map((item, id) => {
            return (
              <Box
                bgColor={select === item.id ? "#4253B7" : "transparent"}
                width={{ base: "105px", md: "120px" }}
                height={"40px"}
                alignItems={"center"}
                paddingX={3}
                paddingY={2}
                borderRadius={"30px"}
                borderWidth={1}
                onClick={() => {
                  setSelect(item.id);
                }}
              >
                <Text
                  color={select === item.id ? "white" : "black"}
                  fontSize={{ base: 13, md: 16 }}
                >
                  {item.label}
                </Text>
              </Box>
            );
          })}
        </HStack>
        {select === "0" ? (
          <LotteryInterface />
        ) : select === "1" ? (
          <CommunityInterface />
        ) : select === "2" ? (
          <EducationalInterface />
        ) : null}
      </Box>
      {/* <Box
        as="button"
        onClick={() => {
          navigate("/chat");
        }}
        bgColor={colors.primary}
        height={"50px"}
        width={"50px"}
        borderRadius={"full"}
        position={"absolute"}
        right={"40px"}
        bottom={"25px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
      >
        <IoMdChatbubbles size={"25px"} color="white" />
      </Box> */}
      <Box position="relative">
        {/* Chat UI */}
        {isChatOpen && (
          <Box
            position="absolute"
            bottom="45px" // Position above the button
            right="25px"
            width={{ base: "90vw", md: "400px" }}
            height="500px"
            border="1px solid #ccc"
            borderRadius="md"
            overflow="hidden"
            bg="white"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            animation="slide-up 0.3s ease-in-out"
            flexDirection="column"
          >
            <VStack
              flex="1"
              overflowY="auto"
              padding="4"
              spacing="4"
              align="stretch"
              bg="gray.50"
            >
              {messages.map((message) => (
                <Box
                  key={message.id}
                  alignSelf={
                    message.sender === "user" ? "flex-end" : "flex-start"
                  }
                  bg={message.sender === "user" ? "blue.100" : "gray.200"}
                  p="2"
                  borderRadius="md"
                  maxWidth="70%"
                >
                  <Text>{message.text}</Text>
                </Box>
              ))}
            </VStack>

            <Box display="flex" p="2" bg="white" borderTop="1px solid #ccc">
              <Input
                placeholder="Type your message..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                flex="1"
              />
              <Button ml="2" bgColor={colors.primary} color={"white"} onClick={handleSendMessage}>
                Send
              </Button>
            </Box>
          </Box>
        )}

        {/* Button */}
        <Box
          as="button"
          onClick={() => setIsChatOpen(!isChatOpen)}
          bgColor={"#4253B7"}
          height={"50px"}
          width={"50px"}
          borderRadius={"full"}
          position={"absolute"}
          right={"25px"}
          bottom={"5px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
        >
          <IoMdChatbubbles size={"25px"} color="white" />
        </Box>

        {/* Slide-Up Animation */}
        <style>
          {`
          @keyframes slide-up {
            from {
              transform: translateY(50px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
        </style>
      </Box>
    </Box>
  );
}
