import { Box } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "./DashboardNav";
import SubNav from "./SubNav";

type Props = {
  title?: any;
};

export default function ({ title }: Props) {
  return (
    <Box zIndex={10000}>
      <DashboardNav />
      <Box
        paddingX={{ base: "15px", md: "11%" }}
        paddingTop={{ base: "65px", md: "65px", lg: '100px' }}
      >
        <SubNav title={title} />
      </Box>
    </Box>
  );
}
