import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DashboardNav from "../../components/DashboardNav";
import { loadtoken } from "../../redux/token";
import { useLocation } from "react-router-dom";
import Request from "../../api/request";
import axios from "axios";

export default function SavedNumbers() {
  // const data = ["01", "05", "12", "45"];
  const baseUrl = Request.url;
  const [data, setData] = useState(["00", "00", "00", "00"]);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(getData, "getData");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");

  useEffect(() => {
    const getDataArr = () => {
      const token = loadtoken();
      console.log(token, "get, unique string");
      setLoading(true);
      axios
        .get(`${baseUrl}/predictor/get-lotteries`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          setGetData(response.data);
          console.log(response.data, "numbers");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    getDataArr();
  }, []);

  return (
    <Box>
      <DashboardNav />
      <Box
        paddingX={{ base: "20px", md: "5%", lg: "11.5%" }}
        paddingTop={"100px"}
      >
        <Text fontSize={28} width={{ base: "60%", md: "100%" }}>
          Saved Numbers
        </Text>
        <Box paddingTop={5}>
          <VStack width={"100%"} alignItems={"flex-start"}>
            <HStack
              justifyContent={"space-between"}
              width={{ base: "100%", md: "70%", lg: "40%" }}
            >
              <Text>Contest #3467</Text>
              <Box>Nov 24, 2023</Box>
            </HStack>
            {getData.map((eachObj: any, index) => {
                const prediction = JSON.parse(eachObj.prediction);
                const predictionCount = prediction.length;
                const predictionDate = eachObj.date; // assuming eachObj contains date
                const gamePlayed = eachObj.game; // assuming eachObj contains game

                return (
                  <Box
                    key={index}
                    width={{ base: "100%", md: "70%", lg: "40%" }}
                    marginBottom={4}
                  >
                    <HStack
                      justifyContent={"space-between"}
                      width={"100%"}
                      paddingY={2}
                    >
                      <Text>{gamePlayed}</Text>
                      <Text>{predictionDate}</Text>
                    </HStack>
                    <HStack
                      display="flex"
                      justifyContent={"space-between"}
                      width={"100%"}
                      paddingY={6}
                      alignContent={"center"}
                      borderBottomWidth={"2px"}
                    >
                      {prediction
                        .slice(0, predictionCount > 4 ? 6 : 4)
                        .map((eachNumber: any, index: number) => (
                          <Box
                            bgColor={"#DBDBDB"}
                            padding={7}
                            borderRadius={8}
                            key={index}
                          >
                            <Text color={"black"}>{eachNumber}</Text>
                          </Box>
                        ))}
                    </HStack>
                  </Box>
                );
              })}
          </VStack>
        </Box>
      </Box>
    </Box>
  );
}
