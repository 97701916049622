import { Box, Center, Text, VStack, Image } from "@chakra-ui/react";
import React from "react";
import DashboardNav from "../../../components/DashboardNav";
import SubNav from "../../../components/SubNav";
import Icons from "../../../assets/Icons";
import MainNav from "../../../components/MainNav";
import Course1 from "../../../assets/course-image.png";

export default function BasicIntroduction() {
  return (
    <Box>
      {/* <DashboardNav /> */}
      <MainNav title={"Basics of Numerology"} />
      <Box
        paddingX={{ base: 4, md: "20px", lg: "150px" }}
        paddingTop={{ base: "100px", md: "100px" }}
        bgColor={"white"}
      >
        <Box>
          {/* <Center> */}
            <VStack>
              <Image src={Course1} width={'full'} height={'300px'} />
              <Text fontWeight={"700"}>BASICS OF NUMEROLOGY</Text>
            </VStack>
          {/* </Center> */}
          <Box>
            <Text>
              Numerology is an ancient and profound metaphysical science that
              delves into the mystical significance of numbers and their impact
              on various facets of life. Rooted in the belief that numbers carry
              inherent meanings and vibrations, numerology seeks to uncover how
              these numerical influences can shape personal characteristics,
              life events, and future outcomes. This esoteric discipline posits
              that by understanding the hidden meanings behind numbers,
              individuals can gain valuable insights into their lives and make
              informed decisions.
              <br />
              <br />
              In the context of the Win Wise app, numerology becomes a
              transformative tool for enhancing lottery predictions. By
              integrating numerological insights, users can develop strategic
              approaches to selecting lottery numbers based on the profound
              significance of numbers in their personal and predictive contexts.
              The Basics of Numerology section serves as a comprehensive
              introduction to this fascinating field, providing users with the
              foundational knowledge needed to apply numerology effectively
              within the app.
            </Text>
          </Box>
          <Box paddingTop={2}>
            <Text>Overview of This Page:</Text>
            <Text>
              The Basics of Numerology page is designed to offer a thorough
              introduction to the fundamental principles and techniques of
              numerology. This section will guide you through key concepts and
              practices, including:
            </Text>
            <Box paddingLeft={5} paddingBottom={4}>
              <Text>
                - Numerology Fundamentals: Understand the core principles of
                numerology, including the belief that numbers possess unique
                vibrational energies that influence various aspects of life.
                <br />
                <br />
                - Core Numerology Numbers: Learn about essential numerology
                numbers such as the Life Path Number, Destiny Number, and Soul
                Urge Number. This includes detailed explanations on how to
                calculate these numbers and interpret their meanings.
                <br />
                <br />- Practical Applications: Discover how to apply
                numerological insights to enhance your lottery prediction
                strategies. This includes translating numerological knowledge
                into actionable steps for selecting numbers based on personal
                and predictive contexts.
              </Text>
            </Box>
            <Box>
              <Text>
                By immersing yourself in this foundational material, you will
                gain a solid understanding of numerology and its practical
                applications. This knowledge will enable you to leverage the Win
                Wise app's features more effectively, integrating numerological
                insights into your lottery strategies for more informed and
                potentially successful predictions.
                <br />
                <br />
                As you progress through the Basics of Numerology section, you
                will build a strong foundation that prepares you to explore
                advanced numerological techniques and their applications in the
                Win Wise app. This page is designed to equip you with the
                essential knowledge needed to make the most of numerology in
                your journey toward achieving lottery success.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
