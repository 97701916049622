import { configureStore } from "@reduxjs/toolkit";
import {createLogger} from 'redux-logger';
import rootReducer from './reducers/rootReducer';
//import {thunk} from 'redux-thunk'
import { GetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";

const LoggerMiddleware = createLogger();

const middleware:any = [];

if (process.env.REACT_APP_NODE_ENV=== 'development') {
  middleware.push(LoggerMiddleware)
}
// ...


const store = configureStore({
    reducer: rootReducer,
  middleware: (getDefaultMiddleware: GetDefaultMiddleware<any>) => {
    return getDefaultMiddleware()
  },
  })
 
  
  export default store